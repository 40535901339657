import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "textarea-input-dialog",
  templateUrl: "textarea-input-dialog.html",
  styleUrls: ["textarea-input-dialog.scss"],
})
export class TextareaInputDialog {
  text: string;
  constructor(@Inject(MAT_DIALOG_DATA) data: string) {
    this.text = data;
  }
}
