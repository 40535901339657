import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { NavigationExtras, Router } from '@angular/router';
import { CustomerDataService } from '../../data-service/customer.data.service';
import { ContactInterface } from '../../interface/contact.interface';
import { CustomerInterface } from '../../interface/customer.interface';
import { AlertService } from '../../service/alert.service';


@Component({
  selector: 'customer-detail-sheet',
  templateUrl: 'detail.html',
})

export class CustomerDetailSheet {
  defaultImage = 'assets/image/default-empty.png';
  data: CustomerInterface;
  contact: ContactInterface;

  constructor(
    private router: Router,
    private bottomSheetRef: MatBottomSheetRef<CustomerDetailSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private id: string,
    private alertService: AlertService,
    private customerDataService: CustomerDataService
  ) {
    this.getData();
  }

  private getData() {
    this.customerDataService.getById(this.id).subscribe({
      next: (x) => {

        this.data = x;
        this.getContactList();
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
        this.bottomSheetRef.dismiss();

      }
    });
  }
  private getContactList() {
    this.customerDataService.getContactById(this.id).subscribe({
      next: (x) => {
        if (x.length != 0) {
          this.contact = x[0];
        }

      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
        this.bottomSheetRef.dismiss();

      }
    });
  }

  openGoogleMap() {
    window.open(`https://www.google.com/maps?q=${this.data.address}`);
  }

  detail() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "id": this.id
      }
    };
    this.bottomSheetRef.dismiss();

    this.router.navigate(["/general/customer-details"], navigationExtras);
  }
}