import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ContactInterface } from "../interface/contact.interface";
import { SupplierInterface, SupplierListInterface, SupplierOptionInterface } from "../interface/supplier.interface";

@Injectable({
    providedIn: 'root',
})
export class SupplierDataService {

    private basePath: string = `${environment.url}/api/Supplier/`;
    constructor(private http: HttpClient) {
    }

    add(value: any) {
        return this.http.post<void>(`${this.basePath}Add`, value);
    }

    edit(id: string, value: any) {
        return this.http.post<void>(`${this.basePath}Edit/${id}`, value);
    }
    delete(id: string) {
        return this.http.get<void>(`${this.basePath}delete/${id}`);
    }
    getListing(date: Date, offset: number, limit: number, text: string) {
        return this.http.post<SupplierListInterface[]>(`${this.basePath}GetListing`, {
            date, offset, limit, text
        });
    }

    getById(id: string) {
        return this.http.get<SupplierInterface>(`${this.basePath}GetById/${id}`);
    }
    getContactById(id: string) {
        return this.http.get<ContactInterface[]>(`${this.basePath}GetContactBySupplierId/${id}`);
    }

    deleteContact(id: string) {
        return this.http.get<void>(`${this.basePath}DeleteContactById/${id}`);
    }

    addContact(id: string, value: any) {
        return this.http.post<void>(`${this.basePath}AddContact/${id}`, value);
    }

    editContact(id: string, value: any) {
        return this.http.post<void>(`${this.basePath}EditContact/${id}`, value);
    }

    SetDefault(id: string, contactId: string) {
        return this.http.get<void>(`${this.basePath}SetDefault/${id}/${contactId}`);
    }

    getOption() {
        return this.http.get<SupplierOptionInterface[]>(`${this.basePath}GetOption`);
    }

    exportExcel(date: Date, offset: number, limit: number, text: string) {
        return this.http.post(`${this.basePath}ExportExcel`, {
            date, offset, limit, text
        }, { responseType: 'blob' });
    }
}