import { Component } from "@angular/core";
import { DashboardDataService } from "src/app/share/data-service/dashboard.data.service";
import { AlertService } from "src/app/share/service/alert.service";
import { UserService } from "src/app/share/service/user.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  option = ["This Week", "This Month", "This Year"];

  cashflow: any;
  isCashFlowLoading: boolean = true;
  cashFlowFilter: number = 0;
  totalCashFlow: number = 0;
  totalPayment: number = 0;
  totalReceipt: number = 0;

  salesChart: any;
  totalSales: number = 0;
  isSalesChartLoading: boolean = true;
  salesChartFilter: number = 0;

  costOfSalesChart: any;
  totalCostOfSales: number = 0;
  isCostOfSalesLoading: boolean = true;
  costOfSalesFilter: number = 0;

  expensesChart: any;
  totalExpenses: number = 0;
  isExpensesLoading: boolean = true;
  expensesFilter: number = 0;

  constructor(
    public userService: UserService,
    private dashboardDataService: DashboardDataService,
    private alertService: AlertService
  ) {
    this.getCashFlowSummary();
    this.getSalesSummary();
    this.getCostOfSalesSummary();
    this.getExpensesSummary();
  }

  private initCashflowChart() {
    this.cashflow = {
      color: ["#6816ce", "#FABB18"],
      grid: {
        left: "8px",
        right: "8px",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },

      xAxis: {
        type: "category",
        data: [],
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
        splitLine: {
          show: false,
        },
      },
      textStyle: {
        fontFamily: "Space Grotesk",
        fontWeight: "500",
      },
      height: "50%",
      series: [
        {
          name: "Receipt",
          type: "bar",
          barWidth: 12,
          data: [],
        },
        {
          name: "Payment",
          type: "bar",
          barWidth: 12,
          data: [],
        },
      ],
    };
  }

  private initSalesChart() {
    this.salesChart = {
      color: ["#6816ce"],
      grid: {
        left: "8px",
        right: "8px",
        bottom: "24px"
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: [
        {
          type: "category",
          data: [],
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "Amount",
          type: "bar",
          barWidth: "60%",
          data: [1, 2],
        },
      ],
    };
  }

  private initCostOfSalesChart() {
    this.costOfSalesChart = {
      color: ["#6816ce"],
      grid: {
        left: "8px",
        right: "8px",
        bottom: "24px"
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: [
        {
          type: "category",
          data: [],
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "Amount",
          type: "bar",
          barWidth: "60%",
          data: [1, 2],
        },
      ],
    };
  }

  private initExpensesChart() {
    this.expensesChart = {
      color: ["#6816ce"],
      grid: {
        left: "8px",
        right: "8px",
        bottom: "24px"
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: [
        {
          type: "category",
          data: [],
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "Amount",
          type: "bar",
          barWidth: "60%",
          data: [1, 2],
        },
      ],
    };
  }

  getCashFlowSummary() {
    this.isCashFlowLoading = true;
    let index = this.cashFlowFilter;
    index++;
    this.totalCashFlow = 0;
    this.totalReceipt = 0;
    this.totalPayment = 0;
    this.dashboardDataService.getCashFlowSummary(index).subscribe({
      next: (x) => {
        this.isCashFlowLoading = false;

        this.totalCashFlow = x.totalCashFlow;
        this.totalReceipt = x.totalReceipt;
        this.totalPayment = x.totalPayment;

        let titleList = x.paymentList.map((x) => x.label);
        this.initCashflowChart();
        this.cashflow.xAxis.data = titleList;
        this.cashflow.series[0].data = x.receiptList.map((x) => {
          return {
            value: x.total,
          };
        });

        this.cashflow.series[1].data = x.paymentList.map((x) => {
          return {
            value: x.total,
          };
        });
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  getSalesSummary() {
    this.isSalesChartLoading = true;
    let index = this.salesChartFilter;
    index++;
    this.totalSales = 0;
    this.dashboardDataService.getAccountSummary("SL", index).subscribe({
      next: (x) => {
        this.isSalesChartLoading = false;
        this.totalSales = x.total;
        this.initSalesChart();
        this.salesChart.xAxis[0].data = x.data.map((y) => y.label);
        this.salesChart.series[0].data = x.data.map((y) => y.total);
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  getCostOfSalesSummary() {
    this.isCostOfSalesLoading = true;
    let index = this.costOfSalesFilter;
    index++;
    this.totalCostOfSales = 0;
    this.dashboardDataService.getAccountSummary("CO", index).subscribe({
      next: (x) => {
        this.isCostOfSalesLoading = false;
        this.totalCostOfSales = x.total;
        this.initCostOfSalesChart();
        this.costOfSalesChart.xAxis[0].data = x.data.map((y) => y.label);
        this.costOfSalesChart.series[0].data = x.data.map((y) => y.total);
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  getExpensesSummary() {
    this.isExpensesLoading = true;
    let index = this.expensesFilter;
    index++;
    this.totalExpenses = 0;
    this.dashboardDataService.getAccountSummary("EP", index).subscribe({
      next: (x) => {
        this.isExpensesLoading = false;
        this.totalExpenses = x.total;
        this.initExpensesChart();
        this.expensesChart.xAxis[0].data = x.data.map((y) => y.label);
        this.expensesChart.series[0].data = x.data.map((y) => y.total);
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }
}
