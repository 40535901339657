import { Component, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CompanyDataService } from "src/app/share/data-service/company.data.service";
import { AlertService } from "src/app/share/service/alert.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FileFunction } from "src/app/share/function/file.function";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/share/service/user.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmationDialog,
  ConfirmationDialogModel,
} from "src/app/share/dialog/confirmation-dialog/confirmation.dialog";
import { CurrencyInterface } from "src/app/share/interface/currency.interface";
import { CurrencyDataService } from "src/app/share/data-service/currency.data.service";
import { BackPageDirective } from "src/app/share/directive/back-page.direactive";

@Component({
  selector: "app-company-setup",
  templateUrl: "./company-setup.html",
})
export class CompanySetupComponent {
  frmGrpFirstStep: UntypedFormGroup;
  frmGrpSecondStep: UntypedFormGroup;

  companyTypeList: string[] = [];
  imgSrc: string;
  cipherText: string;

  label: string = "Company";

  maxDate = new Date();
  @ViewChild("stepper") stepper: MatStepper;

  isCurrencyLoading: boolean;
  currencyList: CurrencyInterface[] = [];
  filterCurrencyList: CurrencyInterface[] = [];
  defaultCountry: string = "Malaysia";

  constructor(
    activatedRoute: ActivatedRoute,
    private currencyDataService: CurrencyDataService,
    private dialog: MatDialog,
    private userService: UserService,
    private companyDataService: CompanyDataService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService
  ) {
    this.initForm();
    this.cipherText = activatedRoute.snapshot.params.key;

    if (this.cipherText) {
      this.isAllowSetup();
    } else {
      location.href = "/";
    }
  }

  private getCurrency() {
    this.isCurrencyLoading = true;
    this.currencyList = [];
    this.filterCurrencyList = [];
    this.currencyDataService.get().subscribe({
      next: (x) => {
        this.isCurrencyLoading = false;
        this.currencyList = x;
        this.frmGrpFirstStep.controls.currency.setValue(this.currencyList[0]);
        this.filterCurrency();
      },
      error: (err) => {
        this.isCurrencyLoading = false;
        new BackPageDirective().onClick();
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  private isAllowSetup() {
    this.ngxSpinnerService.show();
    this.companyDataService.isAllowSetup(this.cipherText).subscribe({
      next: () => {
        this.ngxSpinnerService.hide();
        this.getCompanyTypeList();
        this.getCurrency();
      },
      error: (err) => {
        this.ngxSpinnerService.hide();
        this.alertService.openSnackBar(err.error);
        location.href = "/";
      },
    });
  }

  private getCompanyTypeList() {
    this.ngxSpinnerService.show();
    this.companyDataService.getCompanyTypeList().subscribe({
      next: (x) => {
        this.ngxSpinnerService.hide();
        this.companyTypeList = x;
      },
      error: (err) => {
        this.ngxSpinnerService.hide();
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  private initForm() {
    this.frmGrpFirstStep = new UntypedFormGroup({
      logo: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl(null, [Validators.required]),
      currency: new UntypedFormControl(null, [Validators.required]),
      currencyDescription: new UntypedFormControl(null),
    });

    this.frmGrpSecondStep = new UntypedFormGroup({
      regNo: new UntypedFormControl(null),
      regDate: new UntypedFormControl(null),
      address: new UntypedFormControl(null),
    });
  }

  changeType(val: string) {
    if (val == this.companyTypeList[this.companyTypeList.length - 1]) {
      this.label = "";
    } else {
      this.label = "Company";
    }
  }

  filterCurrency(x?: string) {
    if (x) {
      let txt = x.trim().toLowerCase();
      this.filterCurrencyList = this.currencyList.filter(x =>
        x.country?.trim()?.toLowerCase().includes(txt) ||
        x.sign?.trim()?.toLowerCase().includes(txt) ||
        x.name?.trim()?.toLowerCase().includes(txt));
    }
    else {
      this.filterCurrencyList = Object.assign([], this.currencyList);
    }
  }

  currencyChange(x: CurrencyInterface) {
    if (x.country == this.defaultCountry) {
      this.frmGrpFirstStep.controls.type.setValidators([Validators.required]);
    }
    else this.frmGrpFirstStep.controls.type.setValidators([]);
    this.frmGrpFirstStep.controls.type.updateValueAndValidity();
  }

  back() {
    this.stepper.previous();
  }

  fileChangeEvent(e) {
    if (e.target.files && e.target.files[0]) {
      let file: File = e.target.files[0];
      if (!FileFunction.isImage(file)) {
        this.alertService.openSnackBar("Only Accept Image");
        return;
      }
      if (!FileFunction.isProfileSize(file)) {
        this.alertService.openSnackBar("File size can't be more than 25 MB.");
        return;
      }
      this.frmGrpFirstStep.controls.logo.setValue(file);
      e.target.value = "";
      FileFunction.getBase64(file).then((x) => {
        this.imgSrc = x;
      });
    }
  }
  backLogin() {
    let data: ConfirmationDialogModel = {
      title: "Logout",
      content: "Are you sure want to",
      btnColor: "warn",
      boldContent: `logout`,
      okBtnText: "Yes",
    };
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: data });
    dialogRef.afterClosed().subscribe((x) => {
      if (x) location.href = "/";
    });
  }
  save() {
    if (this.frmGrpFirstStep.invalid || this.frmGrpSecondStep.invalid) return;

    let value = {
      logo: this.frmGrpFirstStep.value.logo,
      name: this.frmGrpFirstStep.value.name,
      type: this.frmGrpFirstStep.value.type,
      regNo: this.frmGrpSecondStep.value.regNo,
      regDate: this.frmGrpSecondStep.value.regDate,
      address: this.frmGrpSecondStep.value.address,
      currency: this.frmGrpFirstStep.value.currency,
      currencyDescription: this.frmGrpFirstStep.value.currencyDescription,
    };

    let frmData = new FormData();
    if (value.logo) frmData.append("logo", value.logo);
    frmData.append("name", value.name);
    frmData.append("currencySign", value.currency.sign);
    let v = value.currencyDescription;
    if (!v) {
      v = value.currency.name;
    }
    frmData.append("currencyName", v);
    if (value.currency.country == this.defaultCountry)
      frmData.append("type", value.type);
    if (value.regNo) frmData.append("regNo", value.regNo);
    if (value.regDate) frmData.append("regDate", value.regDate.toISOString());
    if (value.address) frmData.append("address", value.address);

    this.ngxSpinnerService.show();
    this.companyDataService.setup(this.cipherText, frmData).subscribe({
      next: (x) => {
        this.alertService.openSnackBar("Setup Success");
        this.userService.loginGoTo(x);
      },
      error: (err) => {
        this.ngxSpinnerService.hide();
        this.alertService.openSnackBar(err.error);
      },
    });
  }
}
