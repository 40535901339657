import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../../share/service/auth.service";
import { AlertService } from "../../share/service/alert.service";
import { MemberDataService } from "src/app/share/data-service/member.data.service";
import { NavigationExtras, Router } from "@angular/router";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
})
export class SignUpComponent implements OnInit {
  hidePassword: boolean = true;
  hideConfrimPassword: boolean = true;
  frmGrp: UntypedFormGroup;

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private memberDataService: MemberDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.frmGrp = new UntypedFormGroup(
      {
        email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        name: new UntypedFormControl(null, [Validators.required]),
        password: new UntypedFormControl(null, [
          Validators.required,
          Validators.pattern(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
          ),
        ]),
        confrimPassword: new UntypedFormControl(null, [Validators.required]),
        agree: new UntypedFormControl(false),
      },
      {
        validators: this.confirmPass.bind(this),
      }
    );
  }

  confirmPass(formGroup: UntypedFormGroup) {
    if (formGroup.value.confrimPassword && formGroup.value.password) {
      let status = formGroup.value.confrimPassword != formGroup.value.password;
      if (status) {
        formGroup.controls.confrimPassword.setErrors({ match: true });
      } else formGroup.controls.confrimPassword.setErrors(null);
    }
  }

  signUp() {
    if (this.frmGrp.invalid) return;
    if (!this.frmGrp.value.agree) {
      this.alertService.openSnackBar(
        "Please agree to the Terms of Service and Privacy"
      );
      return;
    }
    let value = this.frmGrp.value;
    this.ngxSpinnerService.show();

    this.memberDataService
      .register(value.email, value.name, value.password)
      .subscribe({
        next: (x) => {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              email: x.email,
            },
          };
          this.ngxSpinnerService.hide();
          this.alertService.openSnackBar(`Register Success`);

          this.router.navigate(
            ["/authentication/verification"],
            navigationExtras
          );
        },
        error: (err) => {
          this.ngxSpinnerService.hide();
          this.alertService.openSnackBar(err.error);
        },
      });
  }

  openPrivacyPolicy() {
    window.open("assets/PrivacyPolicy.pdf");
  }
  openTermsOfService() {
    window.open("assets/TermsOfService.pdf");
  }
}
