import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AppFullComponent } from "./layouts/full/full.component";
import { AuthenticationAuthGuard } from "./share/service/authentication-guard.service";
import { AuthGuard } from "./share/service/guard.service";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: AppFullComponent,
    children: [
      {
        path: "",
        redirectTo: "/dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "sales",
        loadChildren: () =>
          import("./sales/sales.module").then((m) => m.SalesModule),
      },
      {
        path: "purchases",
        loadChildren: () =>
          import("./purchases/purchases.module").then((m) => m.PurchasesModule),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./settings/settings.module").then((m) => m.SettingsModule),
      },
      {
        path: "general",
        loadChildren: () =>
          import("./general/general.module").then((m) => m.GeneralModule),
      },
      {
        path: "journal-entry",
        loadChildren: () =>
          import("./account/journal-entry/journal-entry.module").then((m) => m.JournalEntryModule),
      },
      {
        path: "report",
        loadChildren: () =>
          import("./report/report.module").then((m) => m.ReportModule),
      },
      {
        path: "bank-reconciliation",
        loadChildren: () =>
          import("./bank-reconciliation/bank-reconciliation.module").then((m) => m.BankReconciliationModule),
      },
    ],
  },
  {
    path: "",
    canActivate: [AuthenticationAuthGuard],
    component: AppBlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: '404',
    component: AppBlankComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./not-found/not-found.module').then((m) => m.NotFoundModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled", //scroll to the top
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
