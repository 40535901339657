export class FileFunction {

    static fileSize = 1024 * 1024 * 25; //16mb
    static getBase64(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as any);
            reader.onerror = error => reject(error);
        });
    }
    static isProfileSize(file: File): boolean {
        return file.size <= this.fileSize;
    }


    static isFileSize(file: File): boolean {
        return file.size <= this.fileSize;
    }

    static isImage(file: File): boolean {
        if (file && file['type'] == 'image/svg+xml') return false;
        return file && file['type'].split('/')[0] == 'image';
    }

    static isImageOrPdf(file: File): boolean {
        return this.isImage(file) || file['type'] == 'application/pdf';
    }

    static isPdf(file: File): boolean {
        return file['type'] == 'application/pdf';
    }
    static openPdf(src: string, fileName: string) {
        let pdfWindow = window.open();
        pdfWindow.document.write(`<html<head><title>${fileName ?? ''}</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>`);
        pdfWindow.document.write(`<body><embed width='100%' height='100%' src='${src}' #toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>`);
    }
}