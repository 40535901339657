<div class="login-page">
  <div class="login-form">

    <form [formGroup]="formGroup" (ngSubmit)="login()">
      <div class="logo-container">
        <img src="assets/image/logo.png" class="login-logo" alt="" />
        <div>
          Manage business accounting smarter
        </div>
      </div>
      <mat-card class="form-field " style="margin-bottom: 100px;">

        <div class="sign-in-text">Login</div>
        <mat-card-content>
          <div class="inform mobile-full">
            <mat-label>Email</mat-label>
            <mat-form-field appearance="outline" floatLabel="false">
              <input matInput placeholder="Enter your email" required formControlName="userName" class="uppercase" />
              <mat-error>Email is required</mat-error>
            </mat-form-field>
          </div>
          <div class="inform mobile-full">
            <mat-label>Password</mat-label>
            <mat-form-field appearance="outline" floatLabel="false">
              <input matInput [type]="hide ? 'password' : 'text'" placeholder="Enter your password"
                formControlName="password" required />
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
            </mat-form-field>
          </div>


          <div>
            <div class="forgot-password-link" routerLink="/authentication/forgot-password">
              Forgot Password
            </div>
            <section>
              <mat-checkbox color="primary" formControlName="remember">Remember Me</mat-checkbox>
            </section>
          </div>
          <button mat-raised-button color="primary" class="lg">Login</button>

          <div class="login-other" >
            <ng-container *ngIf="socialAuth">
              <div class="login-other-text">
                Or Login With
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="grid 16px">
                <button mat-raised-button (click)="socialLogin('GOOGLE')" type="button" class="">
                  <img src="assets/image/google.png" alt="">
                </button>

                <button mat-raised-button (click)="socialLogin('FACEBOOK')" type="button" class="facebook">
                  <img src="assets/image/facebook.png" alt="">
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="!socialAuth">
              <div class="login-other-text">
                Or Login With
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="grid 16px">
                <mat-spinner [diameter]="45" style="margin: 0 auto;"></mat-spinner>
              </div>
            </ng-container>
            <div class="sign-up-account">
              Don’t have an account? <span routerLink="/authentication/sign-up"> Sign Up</span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>

</div>