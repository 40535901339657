import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { DocumentInterface } from "../interface/document.interface";
import { ItemPickerInterface } from "../interface/item.interface";
import { DocumentTransferInterface } from "../interface/customer-quotation.interface";

@Injectable({
  providedIn: "root",
})
export class SupplierQuotationDataService {
  private basePath: string = `${environment.url}/api/SupplierQuotation/`;
  constructor(private http: HttpClient) { }
  add(value: any, document: string) {
    return this.http.post<{ id: string }>(`${this.basePath}Add/${document}`, value);
  }
  edit(id: string, value: any) {
    return this.http.post<{ id: string }>(`${this.basePath}Edit/${id}`, value);
  }
  delete(id: string) {
    return this.http.get<void>(`${this.basePath}delete/${id}`);
  }
  publish(id: string) {
    return this.http.get<void>(`${this.basePath}Publish/${id}`);
  }
  getListing(val: any, document: string) {
    return this.http.post<any[]>(
      `${this.basePath}GetListing/${document}`,
      val
    );
  }

  getById(id: string) {
    return this.http.get<any>(
      `${this.basePath}GetById/${id}`
    );
  }

  getDetailById(id: string) {
    return this.http.get<any>(
      `${this.basePath}getDetailById/${id}`
    );
  }

  getDocumentById(id: string) {
    return this.http.get<DocumentInterface[]>(
      `${this.basePath}GetDocumentById/${id}`
    );
  }

  getItemListById(id: string, isTransfer: boolean = false) {
    if (isTransfer) {
      return this.http.get<ItemPickerInterface[]>(
        `${this.basePath}GetTransferItemListById/${id}`
      );
    }
    return this.http.get<ItemPickerInterface[]>(
      `${this.basePath}GetItemListById/${id}`
    );
  }

  generateDocument(id: string, document: string) {
    return this.http.get(`${this.basePath}GenerateDocument/${document}/${id}`, {
      responseType: "blob",
    });
  }

  generateShareLink(id: string, document: string) {
    return this.http.get(`${this.basePath}GenerateShareLink/${document}/${id}`, {
      responseType: "text",
    });
  }

  generateProformaDocument(id: string) {
    return this.http.get(`${this.basePath}GenerateProformaDocument/${id}`, {
      responseType: "blob",
    });
  }

  generateProformaShareLink(id: string) {
    return this.http.get(`${this.basePath}GenerateProformaShareLink/${id}`, {
      responseType: "text",
    });
  }

  exportExcel(val: any, document: string) {
    return this.http.post(
      `${this.basePath}ExportExcel/${document}`,
      val,
      { responseType: "blob" }
    );
  }

  isMax(document: string) {
    return this.http.get(`${this.basePath}IsMax/${document}`, { responseType: "text" });
  }

  getDocumentByIds(ids: string[]) {
    return this.http.post<DocumentInterface[]>(
      `${environment.url}/api/DocumentFolder/GetDocumentByIds`,
      { ids }
    );
  }

  checkBalance(data: {
    transferId: string;
    transferItemId: string;
    transferFrom: string;
    qty: string;
    id: string;
  }) {
    return this.http.post<boolean>(
      `${this.basePath}CheckTransferItem`, data
    );
  }

  getTransferItem(data: {
    document: string;
    customerId?: string;
    supplierId?: string;
    id: string;
    startDate: Date;
    endDate: Date;
  }) {
    return this.http.post<DocumentTransferInterface[]>(
      `${this.basePath}GetTransferItem`, data
    );
  }

  getTransferItemByItemId(id: string) {
    return this.http.get<any[]>(
      `${this.basePath}GetTransferItemByItemId/${id}`);
  }
}
