import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyDataService } from 'src/app/share/data-service/company.data.service';
import { AlertService } from 'src/app/share/service/alert.service';
import { AuthService } from 'src/app/share/service/auth.service';
import { UserService } from 'src/app/share/service/user.service';
import { DrawerService } from '../../share/service/drawer.service';
import { MatDialog } from '@angular/material/dialog';
import { ExpirationDialog } from 'src/app/share/dialog/expiration-dialog/expiration-dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  title: string;
  header: string;
  defaultCompanyImage = "assets/image/default-empty.png";
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;


  constructor(
    private dialog: MatDialog,
    private router: Router,
    public authService: AuthService,
    public drawerService: DrawerService,
    public userService: UserService,
    private companyDataService: CompanyDataService,
    private ngxSpinnerService: NgxSpinnerService,
    private titleElement: Title,
    private alertService: AlertService,) {
    router.events.subscribe((event) => {

      if (event instanceof ActivationEnd) {
        const snapshot = event.snapshot;
        let value = snapshot.data.title;
        let header = snapshot.data.header;
        if (value) {
          this.title = value;
          // value = value?.replace("New", "");
          // value = value?.replace("Details", "");
          // value = value?.replace("Edit", "");

          this.titleElement.setTitle(`${value} - Shoebox`);
        }


        if (header) {
          this.header = header;
        }
      }

    });
  }

  private goTo(goSetting: boolean) {
    if (goSetting)
      this.router.navigate(['/settings/menu']);
    else
      this.router.navigate(["/"]);
  }

  switchCompany(id: string, goSetting: boolean = false) {
    event.stopPropagation();

    this.menuTrigger.toggleMenu();
    let company = this.userService.companyList.find(x => x.companyId == id);

    if (company.isExpired) {
      this.dialog.open(ExpirationDialog, {
        data: true
      })
    }
    else {
      if (this.authService.getCompanyId() != id) {
        this.ngxSpinnerService.show();
        this.companyDataService.switchCompany(id).subscribe({
          next: (x) => {
            this.userService.login(x);
            if (goSetting)
              location.href = "/settings/menu";
            else
              location.href = "/";

          },
          error: (err) => {
            this.ngxSpinnerService.hide();
            this.alertService.openSnackBar(err.error);
          }
        });
      }
      else this.goTo(goSetting)
    }


  }
}
