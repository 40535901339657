import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "../../service/auth.service";
import { CompanyListingInterface } from "../../interface/company.interface";
import { UserService } from "../../service/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyDataService } from "../../data-service/company.data.service";
import { AlertService } from "../../service/alert.service";

@Component({
    selector: "expiration-dialog",
    templateUrl: "expiration-dialog.html",
})
export class ExpirationDialog {
    companyId: string;
    companyList: CompanyListingInterface[] = [];
    constructor(
        private companyDataService: CompanyDataService,
        private ngxSpinnerService: NgxSpinnerService,
        private alertService: AlertService,
        public authService: AuthService,
        public userService: UserService,
        @Inject(MAT_DIALOG_DATA) public isDisplay: boolean) {
        userService.companyList
    }

    getCompanyList() {
        if (!this.userService?.companyList) return [];
        return this.userService?.companyList.filter(x => x.companyId != this.userService.selectedCompany.companyId && !x.isExpired);
    }

    swtichCompany() {
        if (this.authService.getCompanyId() != this.companyId) {
            this.ngxSpinnerService.show();
            this.companyDataService.switchCompany(this.companyId).subscribe({
                next: (x) => {
                    this.userService.login(x);
                    location.href = "/";
                },
                error: (err) => {
                    this.ngxSpinnerService.hide();
                    this.alertService.openSnackBar(err.error);
                }
            });
        }
    }
}
