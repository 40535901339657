<form [formGroup]="frmGrp" (ngSubmit)="submit()">
  <mat-card class="card-details profile-card" *ngIf="isItemLoading">
    <ngx-skeleton-loader [theme]="{
          'border-radius': '15px',
           height: '100px',
          'animation-duration': '1s'
        }">
    </ngx-skeleton-loader>
  </mat-card>
  <div *ngIf="!isItemLoading">
    <div class="bs-grey"></div>
    <div class="bsTitle-f">{{title}} Item</div>

    <mat-form-field appearance="outline" floatLabel="false" class="full-width">
      <input matInput autocomplete="off" formControlName="name" placeholder="Item" [matAutocomplete]="auto"
        [readonly]="frmGrp?.value?.id" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFnItem"
        (optionSelected)="itemChange($event)">
        <mat-option *ngFor="let item of filterItemList" [value]="item">
          {{item.code}} - {{item.name}}
        </mat-option>
      </mat-autocomplete>

      <mat-error>Item is required</mat-error>
      <mat-icon matSuffix *ngIf="!this.frmGrp.controls.name.disabled&&frmGrp?.value?.id"
        (click)="clearItem()">close</mat-icon>
    </mat-form-field>

    <mat-list class="item-desc-list" matRipple (click)="itemDescDialog()">
      <mat-list-item>
        <div class="desc-f" *ngIf="!remark" matLine>Item Description</div>
        <div class="xsValue-f" *ngIf="remark" matLine [innerText]="remark"></div>

        <mat-icon> chevron_right </mat-icon>
      </mat-list-item>
    </mat-list>

    <ng-container *ngIf="!isPass">

      <mat-form-field appearance="outline" floatLabel="false" class="full-width">
        <mat-select formControlName="type" (selectionChange)="changeType($event)">
          <mat-option value="Sales" *ngIf="isSales">SALES</mat-option>
          <mat-option value="Purchase" *ngIf="!isSales">Purchase</mat-option>

          <mat-option value="Select from GL">Select from GL</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="false" class="full-width">
        <mat-select required placeholder="Select GL Code" formControlName="accountId">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search GL code..." [searching]="isAccountLoading"
              [ngModelOptions]="{ standalone: true }" noEntriesFoundLabel="No matching GL Code found"
              (ngModelChange)="filterAccount($event)" ngModel ngDefaultControl>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of filterAccountList" [value]="item.id">
            <span>{{ item.code }} - </span>{{ item.name }}
          </mat-option>
        </mat-select>
        <mat-error>GL Code is required</mat-error>
      </mat-form-field>
    </ng-container>

    <div class="item-row">
      <span class="desc-f" fxFlex>Qty<span *ngIf="getUom()"> ({{getUom()}})</span>*</span>
      <mat-form-field appearance="outline" floatLabel="false" class="side-input">
        <input matInput mask="separator.2" thousandSeparator="," autocomplete="off" formControlName="qty" />
      </mat-form-field>
    </div>

    <div class="item-row">
      <span class="desc-f" fxFlex>Rate ({{userService?.selectedCompany?.currencySign}})*</span>
      <mat-form-field appearance="outline" floatLabel="false" class="side-input">
        <input mask="separator.2" thousandSeparator="," allowNegativeNumbers="true" matInput value="0.00"
          autocomplete="off" formControlName="rate" />
        <!-- <mat-error *ngIf="frmGrp.controls.rate?.errors?.required">Rate is required</mat-error>
                  <mat-error *ngIf="frmGrp.controls.rate?.errors?.notPositive">Rate cannot less than 0 </mat-error> -->
      </mat-form-field>
    </div>

    <div class="item-row">
      <span class="desc-f" fxFlex>Discount*</span>
      <mat-button-toggle-group formControlName="isPercentage" (change)="onDiscountChange($event.value)"
        class="btn-toggle sm m-r-6">
        <mat-button-toggle [value]="false">{{userService?.selectedCompany?.currencySign}}</mat-button-toggle>
        <mat-button-toggle [value]="true">%</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-form-field appearance="outline" floatLabel="false" class="side-input">
        <input matInput value="0.00" autocomplete="off" formControlName="discount" mask="separator.2"
          thousandSeparator="," />
        <!-- <mat-error *ngIf="frmGrp.controls.discount?.errors?.notNegative">Rate cannot negative value
                  </mat-error> -->
      </mat-form-field>
    </div>

    <div class="item-row">
      <span class="desc-f" fxFlex>Tax</span>
      <mat-form-field appearance="outline" floatLabel="false" class="side-input">
        <mat-select placeholder="Select Tax" formControlName="taxId">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search Tax..." [searching]="isTaxLoading"
              [ngModelOptions]="{standalone: true}" noEntriesFoundLabel="No matching tax found"
              (ngModelChange)="filterTax($event)" ngModel ngDefaultControl>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of filterTaxList" [value]="item.id">
            {{item.name}} ({{item.rate}}%)
          </mat-option>
        </mat-select>
        <mat-icon matSuffix *ngIf="frmGrp?.value?.taxId" (click)="clearTax()">close</mat-icon>
      </mat-form-field>
    </div>

    <div class="total-item-row">
      <span class="total-label" fxFlex>Total</span>
      <div class="total-value">{{userService?.selectedCompany?.currencySign}} {{getTotal() | number : '1.2-2'}}</div>
    </div>
    <button mat-flat-button class="bottom-sheet-btn">{{title}}</button>
    <div class="bs-black"></div>
  </div>
</form>