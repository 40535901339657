import { Component, Inject } from "@angular/core";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";

@Component({
  selector: "listing-filter",
  templateUrl: "listing-filter.html",
})
export class ListingFilter {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<ListingFilter>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data
  ) {}

  
}
