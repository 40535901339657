import { Component, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { ItemDataService } from "src/app/share/data-service/item.data.service";
import { TaxDataService } from "src/app/share/data-service/tax.data.service";
import {
  ItemOptionInterface,
  ItemPickerInterface,
} from "src/app/share/interface/item.interface";
import { AlertService } from "src/app/share/service/alert.service";
import { TextareaInputDialog } from "../../dialog/textarea-input-dialog/textarea-input-dialog";
import { ValidatorFunction } from "../../function/validator.function";
import { TaxOptionInterface } from "../../interface/tax.interface";
import { UserService } from "../../service/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerQuotationDataService } from "../../data-service/customer-quotation.data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SupplierQuotationDataService } from "../../data-service/supplier-quotation.data.service";
import { Observable } from "rxjs";

@Component({
  selector: "item-picker",
  templateUrl: "item-picker.html",
})
export class ItemPicker {
  isItemLoading: boolean = true;
  isTaxLoading: boolean = true;

  itemList: ItemOptionInterface[] = [];
  filterItemList: ItemOptionInterface[] = [];

  taxList: TaxOptionInterface[] = [];
  filterTaxList: TaxOptionInterface[] = [];

  frmGrp: UntypedFormGroup;
  title: string;

  remark: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private customerQuotationDataService: CustomerQuotationDataService,
    private supplierQuotationDataService: SupplierQuotationDataService,
    private bottomSheetRef: MatBottomSheetRef<ItemPicker>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: ItemPickerInterface,
    private alertService: AlertService,
    private itemDataService: ItemDataService,
    private taxDataService: TaxDataService,
    private dialog: MatDialog
  ) {

    if (this.data) this.title = "Save";
    else this.title = "Add";

    this.remark = data?.remark;
    this.initForm();

    this.getItem();
    this.getIax();
    if ((this.data?.documentId || this.data?.isTransfer)) {
      this.frmGrp.controls.name.disable();
      if (this.data.isTransfer) {
        this.frmGrp.controls.qty.addValidators([Validators.min(this.data.transferQty)])
      }
    }
  }

  private initForm() {
    this.frmGrp = new UntypedFormGroup({
      id: new UntypedFormControl(this.data?.itemId),
      name: new UntypedFormControl(this.data?.name, [Validators.required]),
      qty: new UntypedFormControl(this.data?.qty || 1, [
        Validators.required,
        ValidatorFunction.notPositive(),
      ]),
      rate: new UntypedFormControl(this.data?.rate, [
        Validators.required
      ]),
      isPercentage: new UntypedFormControl(this.data?.isPercentage ?? false),
      discount: new UntypedFormControl(this.data?.discount, [
        ValidatorFunction.notNegative(),
      ]),
      taxId: new UntypedFormControl(this.data?.taxId),
    });

    this.frmGrp.controls.name.valueChanges.subscribe((x) => {
      if (typeof x == "string") this.filterItem(x);
    });
  }

  private getItem() {
    this.itemDataService.getOption().subscribe({
      next: (x) => {
        this.isItemLoading = false;
        this.itemList = x;
        if (this.data?.itemId) {
          this.frmGrp.controls.name.setValue(
            this.itemList.find((y) => y.id == this.data?.itemId)
          );
        }
        this.filterItem();
      },
      error: (err) => {
        this.isItemLoading = false;
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  private getIax() {
    this.taxDataService.getOption().subscribe({
      next: (x) => {
        this.isTaxLoading = false;
        this.taxList = x;
        this.filterTax();
      },
      error: (err) => {
        this.isTaxLoading = false;
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  displayFnItem(item: any): string {
    if (!item) return null;
    if (typeof item == "string") return item;
    return `${item.code} - ${item.name}`;
  }

  onDiscountChange(val: boolean) {
    this.frmGrp.controls.discount.clearValidators();
    if (val) {
      this.frmGrp.controls.discount.setValidators([
        Validators.min(0),
        Validators.max(100),
      ]);
    } else {
      this.frmGrp.controls.discount.setValidators([
        ValidatorFunction.notNegative(),
      ]);
    }
    this.frmGrp.controls.discount.updateValueAndValidity();
  }

  filterItem(val?: string) {
    if (!val) this.filterItemList = Object.assign([], this.itemList);
    else {
      val = val.trim().toLowerCase();
      this.filterItemList = Object.assign(
        [],
        this.itemList.filter(
          (x) =>
            x.name.toLowerCase().includes(val) ||
            x.code.toLowerCase().includes(val)
        )
      );
    }
  }

  filterTax(val?: string) {
    if (!val) this.filterTaxList = Object.assign([], this.taxList);
    else {
      val = val.trim().toLowerCase();
      this.filterTaxList = Object.assign(
        [],
        this.taxList.filter(
          (x) =>
            x.name.toLowerCase().includes(val) ||
            x.rate.toString().toLowerCase().includes(val)
        )
      );
    }
  }

  clearTax() {
    event.stopImmediatePropagation();
    this.frmGrp.controls.taxId.setValue(null);
  }
  getUom() {
    if (this.frmGrp?.value?.id) {
      let item = this.itemList.find((x) => x.id == this.frmGrp.value.id);
      return item?.uom;
    } else return null;
  }

  clearItem() {
    event.stopImmediatePropagation();
    if (this.frmGrp.controls.name.disabled) return
    this.frmGrp.controls.name.setValue(null);
    this.frmGrp.controls.id.setValue(null);
    this.frmGrp.controls.rate.setValue(null);
    this.frmGrp.controls.taxId.setValue(null);
  }

  itemChange(val: MatAutocompleteSelectedEvent) {

    let isSale = window.location.pathname.split("/")[1] == "sales";
    let value = val.option.value;
    if (isSale) {
      this.frmGrp.controls.rate.setValue(value.price);
    }
    else
      this.frmGrp.controls.rate.setValue(value.cost);

    this.frmGrp.controls.id.setValue(value.id);
    this.frmGrp.controls.taxId.setValue(value.taxId);
  }

  getDiscountAmt() {
    if (this.frmGrp.invalid) return 0;
    let val = this.frmGrp.value;
    let subTotal = val.qty * val.rate;
    let discountAmt = val.discount || 0;
    if (val.isPercentage) {
      return subTotal * (discountAmt / 100);

    }
    return discountAmt;
  }

  getSubTotal() {
    let val = this.frmGrp.value;
    let subTotal = val.qty * val.rate;
    let discountAmt = this.getDiscountAmt();
    if (subTotal < discountAmt) {
      setTimeout(() => {
        this.frmGrp.controls.discount.setValue(null);
        this.frmGrp.controls.discount.updateValueAndValidity();
        discountAmt = 0;
      }, 0);
    }

    return subTotal - discountAmt;
  }

  getTaxAmt() {
    if (this.frmGrp.invalid) return 0;
    let val = this.frmGrp.value;
    let total = this.getSubTotal();

    if (val.taxId) {
      let tax = this.taxList.find((x) => x.id == val.taxId);
      return total - total * ((100 - tax?.rate) / 100);
    }
    return 0;
  }

  getTotal() {
    if (this.frmGrp.invalid) return 0;
    let total = this.getSubTotal();
    let taxAmt = this.getTaxAmt();
    return total + taxAmt;
  }

  submit() {
    if (this.frmGrp.invalid) return;
    if (this.data?.transferItemId && this.data?.transferItemId != '00000000-0000-0000-0000-000000000000') {
      this.checkBalance();
    }
    else
      this.save();
  }

  private checkBalance() {
    let id: string;
    if (this.router.url.includes("/sales/edit-") || this.router.url.includes("/purchases/edit-")) {
      id = this.activatedRoute.snapshot.queryParams.id;
    }
    let val = this.frmGrp.getRawValue();

    let data = {
      transferId: this.data.documentId,
      transferItemId: this.data.transferItemId,
      transferFrom: this.data.document,
      qty: val.qty,
      id
    };
    this.ngxSpinnerService.show();

    let sub: Observable<boolean> = this.customerQuotationDataService.checkBalance(data);
    if (this.router.url.includes("/purchases")) {
      sub= this.supplierQuotationDataService.checkBalance(data);

    }
    sub.subscribe({
      next: () => {
        this.ngxSpinnerService.hide();

        this.save();
      },
      error: (err) => {
        this.ngxSpinnerService.hide();
        this.alertService.openSnackBar(err.error);
      },
    })
  }


  private save() {
    let val = this.frmGrp.getRawValue();

    let item = this.itemList.find((x) => x.id == val.id);
    if (this.data?.isTransfer) {
      this.data.isFullTransfer = this.data.transferQty >= val.qty;
    }
    let result: ItemPickerInterface = {
      itemId: item?.id,
      name: item?.name ?? val.name,
      code: item?.code,
      rate: val.rate,
      qty: val.qty,
      discount: val.discount,
      taxId: val.taxId,
      uom: item?.uom,
      taxRate: 0,
      total: 0,
      isPercentage: val.isPercentage,
      remark: this.remark,
      document: this.data?.document,
      documentId: this.data?.documentId,
      documentNo: this.data?.documentNo,
      // transferId: this.data?.transferId,
      // transferFrom: this.data?.transferFrom,
      // transferNo: this.data?.transferNo,
      transferItemId: this.data?.transferItemId,
      transferQty: this.data?.transferQty,
      isTransfer: this.data?.isTransfer,
      isFullTransfer: this.data?.isFullTransfer
    };
    if (val?.taxId) {
      let tax = this.taxList.find((x) => x.id == val?.taxId);
      result.taxRate = tax?.rate;
    }
    result.total = this.getTotal();
    if (this.data) {
      result.id = this.data?.id;
    }

    this.bottomSheetRef.dismiss(result);
  }

  itemDescDialog() {
    let dialogRef = this.dialog.open(TextareaInputDialog, {
      data: this.remark,
    });
    dialogRef.afterClosed().subscribe((x) => {
      if (typeof x == "object") {
        this.remark = x.val;
      }
    });
  }
}
