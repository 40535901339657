<div class="login-page">
  <div class="login-form">

    <div class="logo-container">
      <img src="assets/image/logo.png" class="login-logo" alt="" />
      <div>
        Manage business accounting smarter
      </div>
    </div>
    <mat-card class="form-field">

      <div class="sign-in-text">Reset Password</div>

      <ng-container *ngIf="!otpIsSuccess">
        <form [formGroup]="formGroup" (ngSubmit)="reset()">
          <mat-card-content style="display: block;">
            <div *ngIf="success">
              <ng-container *ngIf="!otpIsSuccess">
                <div class="verification-container" style="padding: 50px 0px;">
                  <div class="verification-email">
                    We've sent a 4-digit OTP code to <b>{{email}}</b>
                    <br>
                    Please enter the code to continue
                  </div>
                  <div class="center">
                    <ngx-otp-input [config]="otpInputConfig" (fill)="onCodeCompleted($event)">
                    </ngx-otp-input>
                  </div>
                  <!-- <code-input [codeLength]="4" (codeCompleted)="onCodeCompleted($event)">
                  </code-input> -->

                  <div class="sign-up-account">
                    <span *ngIf="!sec">Didn’t receive the code? <span (click)="reset()">Resend Code</span></span>
                    <span *ngIf="sec">Resend OTP in {{sec}}s</span>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="inform mobile-full" *ngIf="!success">
              <mat-label>Email</mat-label>
              <mat-form-field appearance="outline" floatLabel="false">
                <input matInput required type="email" formControlName="email" />
                <mat-error>Email is required</mat-error>
              </mat-form-field>
            </div>
            <ng-container *ngIf="!success">

              <div>
                <div class="forgot-password-link" routerLink="/authentication/login">
                  Sign In Now
                </div>
                <section style="opacity: 0">1</section>
              </div>
              <button mat-raised-button color="primary" class="lg">
                Reset Now
              </button>
            </ng-container>
          </mat-card-content>
        </form>
      </ng-container>

      <ng-container *ngIf="otpIsSuccess">
        <form [formGroup]="passwordFrmGrp" (ngSubmit)="save()">
          <mat-card-content>
            <div class="inform mobile-full">
              <mat-label>Password</mat-label>
              <mat-form-field appearance="outline" floatLabel="false">
                <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="Enter your password"
                  formControlName="password" required />
                <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                  <mat-icon>{{ hidePassword ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
                <mat-error *ngIf="passwordFrmGrp.controls.password?.errors?.required">Password is required</mat-error>
                <mat-error *ngIf="passwordFrmGrp.controls.password?.errors?.pattern">Password minimum 8 characters, at
                  least
                  one uppercase letter, one lowercase letter, one number and one special character</mat-error>
              </mat-form-field>
            </div>

            <div class="inform mobile-full">
              <mat-label>Confirm Password</mat-label>
              <mat-form-field appearance="outline" floatLabel="false">
                <input matInput [type]="hideConfrimPassword ? 'password' : 'text'" placeholder="Enter your password"
                  formControlName="confrimPassword" required />
                <button type="button" mat-icon-button matSuffix (click)="hideConfrimPassword = !hideConfrimPassword"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfrimPassword">
                  <mat-icon>{{ hideConfrimPassword ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
                <mat-error *ngIf="passwordFrmGrp.controls.confrimPassword?.errors?.required">Confrim Password is
                  required
                </mat-error>
                <mat-error *ngIf="passwordFrmGrp.controls.confrimPassword?.errors?.match">Confrim Password not match
                  with password
                </mat-error>
              </mat-form-field>
            </div>
            <button mat-raised-button color="primary" class="lg">
              Change Password
            </button>
          </mat-card-content>
        </form>
      </ng-container>

    </mat-card>

  </div>
  <!-- <div class="crm-info">
    <div>
      <div class="title">Welcome to BEYONARY ADMIN</div>
      <div class="desc">
        Turn your sales process into a state of the art, revenue-generating
        machine. Manage Your Pipeline with Total Visibility
      </div>
    </div>
  </div> -->
</div>