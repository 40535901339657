import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CompanySetupComponent } from "./company-setup/company-setup";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { VerificationComponent } from "./verification/verification";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "sign-up",
        component: SignUpComponent,
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
      },
      {
        path: "verification",
        component: VerificationComponent,
      },
      {
        path: "company-setup",
        component: CompanySetupComponent,
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
