import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertService } from './share/service/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(swUpdate: SwUpdate, alertService: AlertService) {
    if (swUpdate.isEnabled) {
      swUpdate.versionUpdates.subscribe((x) => {
        if (x.type === 'VERSION_READY') {
          alertService.openSnackBar("A newer version is now available. Refresh the page now to update the cache");
          window.location.reload();
        }
      });
    }
  }
}
