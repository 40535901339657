import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ItemInterface, ItemListInterface, ItemOptionInterface } from "../interface/item.interface";

@Injectable({
    providedIn: 'root',
})
export class ItemDataService {
    private basePath: string = `${environment.url}/api/Item/`;
    constructor(private http: HttpClient) {
    }


    add(value: any) {
        return this.http.post<void>(`${this.basePath}Add`, value);
    }

    edit(id: string, value: any) {
        return this.http.post<void>(`${this.basePath}Edit/${id}`, value);
    }
    delete(id: string) {
        return this.http.get<void>(`${this.basePath}delete/${id}`);
    }
    getListing(date: Date, offset: number, limit: number, text: string) {
        return this.http.post<ItemListInterface[]>(`${this.basePath}GetListing`, {
            date, offset, limit, text
        });
    }

    getById(id: string) {
        return this.http.get<ItemInterface>(`${this.basePath}GetById/${id}`);
    }

    getOption() {
        return this.http.get<ItemOptionInterface[]>(`${this.basePath}getOption`);
    }
    exportExcel(date: Date, offset: number, limit: number, text: string) {
        return this.http.post(`${this.basePath}ExportExcel`, {
            date, offset, limit, text
        }, { responseType: 'blob' });
    }

}
