import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RoleActionInterface, RoleListingInterface, RoleOptionInterface } from "../interface/role.interface";


@Injectable({
    providedIn: 'root',
})
export class RoleDataService {
    private basePath: string = `${environment.url}/api/Role/`;
    constructor(private http: HttpClient) { }

    getActionList() {
        return this.http.get<RoleActionInterface[]>(`${this.basePath}GetActionList`);
    }

    getOption() {
        return this.http.get<RoleOptionInterface[]>(`${this.basePath}GetOption`);
    }

    getListing() {
        return this.http.get<RoleListingInterface[]>(`${this.basePath}GetListing`);
    }

    add(value: any) {
        return this.http.post<void>(`${this.basePath}Add`, value);
    }
    edit(id: string, value: any) {
        return this.http.post<void>(`${this.basePath}Edit/${id}`, value);
    }
    delete(id: string) {
        return this.http.get<void>(`${this.basePath}delete/${id}`);
    }

    getById(id: string) {
        return this.http.get<{ id: string, name: string, selectedIds: string[] }>(`${this.basePath}getById/${id}`);
    }

    getACL(){
        return this.http.get<any>(`${this.basePath}GetCode`);
    }
}