<div class="mobile-quick">
  <div routerLink="/sales/quotation-list">
    <mat-icon>description</mat-icon>
    <div>Quotation</div>
  </div>
  <div routerLink="/sales/invoice-list">
    <mat-icon>request_quote</mat-icon>
    <div>Invoice</div>
  </div>
  <div class="shoebox-circle cursor-pointer" routerLink="/general/shoebox">
    <img src="./assets/image/shoebox-action.png" alt="" />
  </div>
  <div routerLink="/sales/cn-list">
    <mat-icon>paid</mat-icon>
    <div>Credit Note</div>
  </div>
  <div routerLink="/sales/receipt-list">
    <mat-icon>person</mat-icon>
    <div>Receipt</div>
  </div>
</div>

<mat-tab-group mat-align-tabs="center" dynamicHeight>
  <mat-tab label="Performance">
    <div class="content-container small">
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="100">
          <mat-card class="card-details chart">
            <mat-card-header>
              <mat-card-title>Total Sales</mat-card-title>

              <mat-card-subtitle class="color-primary"
                >{{userService?.selectedCompany?.currencySign}} {{ totalSales | number: "1.2-2" }}</mat-card-subtitle
              >
              <button
                mat-flat-button
                class="card-filter-btn"
                [matMenuTriggerFor]="salesMenu"
              >
                {{ option[salesChartFilter] }} <mat-icon>event</mat-icon>
              </button>
              <mat-menu #salesMenu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let item of option; let i = index"
                  (click)="salesChartFilter = i; getSalesSummary()"
                  [class.active]="salesChartFilter == i"
                >
                  {{ item }}
                </button>
              </mat-menu>
            </mat-card-header>

            <div class="content-wrapper">
              <div style="width: 100%">
                <div
                  echarts
                  class="bar-chart"
                  [options]="salesChart"
                  [loading]="isSalesChartLoading"
                ></div>
              </div>
            </div>
          </mat-card>
        </div>

        <div fxFlex="100">
          <mat-card class="card-details chart">
            <mat-card-header>
              <mat-card-title>Total Cost of Sales</mat-card-title>

              <mat-card-subtitle class="color-primary"
                >{{userService?.selectedCompany?.currencySign}} {{ totalCostOfSales | number: "1.2-2" }}</mat-card-subtitle
              >
              <button
                mat-flat-button
                class="card-filter-btn"
                [matMenuTriggerFor]="costOfSalesMenu"
              >
                {{ option[costOfSalesFilter] }} <mat-icon>event</mat-icon>
              </button>
              <mat-menu #costOfSalesMenu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let item of option; let i = index"
                  (click)="costOfSalesFilter = i; getCostOfSalesSummary()"
                  [class.active]="costOfSalesFilter == i"
                >
                  {{ item }}
                </button>
              </mat-menu>
            </mat-card-header>

            <div class="content-wrapper">
              <div style="width: 100%">
                <div
                  echarts
                  class="bar-chart"
                  [options]="costOfSalesChart"
                  [loading]="isCostOfSalesLoading"
                ></div>
              </div>
            </div>
          </mat-card>
        </div>

        <div fxFlex="100">
          <mat-card class="card-details chart">
            <mat-card-header>
              <mat-card-title>Total Expenses</mat-card-title>

              <mat-card-subtitle class="color-primary"
                >{{userService?.selectedCompany?.currencySign}} {{ totalExpenses | number: "1.2-2" }}</mat-card-subtitle
              >
              <button
                mat-flat-button
                class="card-filter-btn"
                [matMenuTriggerFor]="expensesMenu"
              >
                {{ option[expensesFilter] }} <mat-icon>event</mat-icon>
              </button>
              <mat-menu #expensesMenu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let item of option; let i = index"
                  (click)="expensesFilter = i; getExpensesSummary()"
                  [class.active]="expensesFilter == i"
                >
                  {{ item }}
                </button>
              </mat-menu>
            </mat-card-header>

            <div class="content-wrapper">
              <div style="width: 100%">
                <div
                  echarts
                  class="bar-chart"
                  [options]="expensesChart"
                  [loading]="isExpensesLoading"
                ></div>
              </div>
            </div>
          </mat-card>
        </div>

      </div>
    </div>
  </mat-tab>

  <mat-tab label="Cashflow">
    <div class="content-container small">
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="100">
          <mat-card class="card-details chart">
            <mat-card-header>
              <mat-card-title>Cashflow</mat-card-title>

              <mat-card-subtitle class="color-primary"
                >{{userService?.selectedCompany?.currencySign}} {{ totalCashFlow | number: "1.2-2" }}</mat-card-subtitle
              >
              <button
                mat-flat-button
                class="card-filter-btn"
                [matMenuTriggerFor]="expenseMenu"
              >
                {{ option[cashFlowFilter] }} <mat-icon>event</mat-icon>
              </button>
              <mat-menu #expenseMenu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let item of option; let i = index"
                  (click)="cashFlowFilter = i; getCashFlowSummary()"
                  [class.active]="cashFlowFilter == i"
                >
                  {{ item }}
                </button>
              </mat-menu>
            </mat-card-header>

            <div class="content-wrapper">
              <div style="width: 100%">
                <div
                  echarts
                  [loading]="isCashFlowLoading"
                  [options]="cashflow"
                  class="bar-chart"
                ></div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="content-wrapper">
              <div class="left">
                <div class="label-desc color-primary">Total Receipt</div>
                <div class="label-value">
                  {{userService?.selectedCompany?.currencySign}} {{ totalReceipt | number: "1.2-2" }}
                </div>
              </div>
              <div class="right">
                <div class="label-desc color-secondary">Total Payment</div>
                <div class="label-value">
                  {{userService?.selectedCompany?.currencySign}} {{ totalPayment | number: "1.2-2" }}
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
