import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxOtpInputComponent, NgxOtpInputConfig } from "ngx-otp-input";
import { NgxSpinnerService } from "ngx-spinner";
import { MemberDataService } from "src/app/share/data-service/member.data.service";
import { AlertService } from "src/app/share/service/alert.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {


  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    numericInputMode: true,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };

  success: boolean;
  otpIsSuccess: boolean;
  formGroup: UntypedFormGroup;
  hidePassword: boolean = true;
  hideConfrimPassword: boolean = true;
  passwordFrmGrp: UntypedFormGroup;
  otp: string;

  sec = 0;
  interval;
  constructor(
    private alertService: AlertService,
    private ngxSpinnerService: NgxSpinnerService,
    private memberDataService: MemberDataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });
    this.passwordFrmGrp = new UntypedFormGroup(
      {
        password: new UntypedFormControl(null, [
          Validators.required,
          Validators.pattern(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
          ),
        ]),
        confrimPassword: new UntypedFormControl(null, [Validators.required]),
      },
      {
        validators: this.confirmPass.bind(this),
      }
    );
  }
  confirmPass(formGroup: UntypedFormGroup) {
    if (formGroup.value.confrimPassword && formGroup.value.password) {
      let status = formGroup.value.confrimPassword != formGroup.value.password;
      if (status) {
        formGroup.controls.confrimPassword.setErrors({ match: true });
      } else formGroup.controls.confrimPassword.setErrors(null);
    }
  }

  reset() {
    if (this.formGroup.invalid) return;
    this.ngxSpinnerService.show();
    this.memberDataService
      .sendResetPasswordByEmail(this.formGroup.value.email)
      .subscribe({
        next: () => {
          this.ngxSpinnerService.hide();
          this.success = true;
          this.sec = 60;
          this.interval = setInterval(() => {
            this.sec--;
            if (this.sec == 0) {
              clearInterval(this.interval);
            }
          }, 1000);
        },
        error: (err) => {
          this.ngxSpinnerService.hide();
          this.alertService.openSnackBar(err.error);
        },
      });
  }

  onCodeCompleted(e) {
    this.otp = e;
    this.ngxSpinnerService.show();
    this.memberDataService
      .resetPasswordVerify(this.formGroup.value.email, e)
      .subscribe({
        next: () => {
          this.ngxSpinnerService.hide();
          this.otpIsSuccess = true;
        },
        error: (err) => {
          this.ngxSpinnerService.hide();
          this.alertService.openSnackBar(err.error);
        },
      });
  }

  save() {
    if (this.passwordFrmGrp.invalid) return;

    this.ngxSpinnerService.show();
    this.memberDataService
      .resetPassword(
        this.formGroup.value.email,
        this.otp,
        this.passwordFrmGrp.value.password
      )
      .subscribe({
        next: () => {
          this.ngxSpinnerService.hide();
          this.alertService.openSnackBar("Reset Password Success");
          this.router.navigate(["/"]);
        },
        error: (err) => {
          this.ngxSpinnerService.hide();
          this.alertService.openSnackBar(err.error);
        },
      });
  }
}
