import { Component } from "@angular/core";
import { UserService } from "src/app/share/service/user.service";
import { AuthService } from "../../share/service/auth.service";
import { Router } from "@angular/router";
import { faCoffee, faTruckRampBox } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.scss"],
})
export class AppDrawerComponent {
  faTruckRampBox = faTruckRampBox;
  faCoffee = faCoffee;
  panelOpenState = false;
  constructor(private authService: AuthService,public userService:UserService, private router:Router) {
    
  }

  logout() {
    
    this.authService.logout();
  }

  navigateToNew(link) {
    event.stopPropagation();
    this.router.navigate([link]);
  }
}
