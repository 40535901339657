const env = {
  local: {
    production: false,
    url: "https://localhost:5001",
    googleLoginProviderId: "329485625220-glt0impp1l101nhgllp2ptai77gd6mib.apps.googleusercontent.com",
    facebookLoginProviderId: "198971319083394",
  },
  dev: {
    production: false,
    url: "https://shoebox-dev-api.beyonary.com",
    googleLoginProviderId: "329485625220-glt0impp1l101nhgllp2ptai77gd6mib.apps.googleusercontent.com",
    facebookLoginProviderId: "198971319083394",
  },
  uat: {
    production: true,
    url: "https://uat-api.shoebox.my",
    googleLoginProviderId: "329485625220-glt0impp1l101nhgllp2ptai77gd6mib.apps.googleusercontent.com",
    facebookLoginProviderId: "198971319083394",
  },
  prod: {
    production: false,
    url: "https://app-api.shoebox.my",
    googleLoginProviderId: "830188823545-0sr5rhhfdr2mmfe048kdfmqcgblcesrk.apps.googleusercontent.com",
    facebookLoginProviderId: "1558088181235098",
  }
};

export const environment = env.dev;
