import { OnInit } from "@angular/core";
import { Component, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { AlertService } from "../../service/alert.service";

export interface SingleTextInputModel {
    title: string;
    textLabel?: string;
    textPlaceholder?: string;
    onSave(name: string): Observable<void>;
    value?: string;
    successMsg?: string;
    cancelBtnText?: string;
    okBtnText?: string;
}

@Component({
    selector: 'single-text-input-dialog',
    templateUrl: 'single-text-input-dialog.html',
    styleUrls: ['single-text-input-dialog.scss'],
})
export class SingleTextInputDialog implements OnInit {

    frmGrp: UntypedFormGroup;

    constructor(private dialogRef: MatDialogRef<SingleTextInputDialog>, @Inject(MAT_DIALOG_DATA) public data: SingleTextInputModel, private alertService: AlertService, private ngxSpinnerService: NgxSpinnerService,
    ) {
        if (!data) {
            data = {} as any;
        }
    }
    ngOnInit(): void {
        this.frmGrp = new UntypedFormGroup({
            name: new UntypedFormControl(this.data.value ?? null, [Validators.required]),
        });
    }
    save() {
        if (this.frmGrp.invalid) return;
        this.ngxSpinnerService.show();
        this.data.onSave(this.frmGrp.value.name).subscribe({
            next: () => {
                this.ngxSpinnerService.hide();
                this.alertService.openSnackBar(this.data.successMsg ?? "Create Success");
                this.dialogRef.close(true);
            }, error: (err) => {
                this.ngxSpinnerService.hide();
                this.alertService.openSnackBar(err.error);
            }
        })

    }


}