import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CurrencyInterface } from "../interface/currency.interface";

@Injectable({
    providedIn: 'root',
})
export class CurrencyDataService {
    private basePath: string = `${environment.url}/api/Currency`;
    constructor(private http: HttpClient) {
    }

    get() {
        return this.http.get<CurrencyInterface[]>(`${this.basePath}`);
    }
}