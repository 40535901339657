import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, HostListener, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { UserService } from "../../share/service/user.service";
import { DrawerService } from "../../share/service/drawer.service";
import { NavigationEnd, Router } from "@angular/router";


@Component({
  selector: "app-full",
  templateUrl: "./full.component.html",
  styleUrls: [],
})
export class AppFullComponent implements AfterContentChecked {
  @ViewChild("drawer") public drawer: MatDrawer;
  screenWidth: number;
  isHideHeader: boolean;
  constructor(
    private drawerService: DrawerService,
    private userService: UserService,
    router: Router,
  ) {
    this.screenWidth = window.innerWidth;

    this.userService.onInit();


  }




  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }
  ngAfterContentChecked(): void {
    this.drawerService.drawer = this.drawer;
  }
  active() {
    setTimeout(() => {
      document.getElementById("page-container").scrollTo(0, 0)
    }, 0);
  }

  private isMobile() {
    return this.screenWidth <= 768;
  }

}
