import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthenticationRoutingModule } from "./authentication-routing.module";
import { LoginComponent } from "./login/login.component";
import { MaterialModule } from "../material-module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { VerificationComponent } from "./verification/verification";
import { CodeInputModule } from "angular-code-input";
import { CompanySetupComponent } from "./company-setup/company-setup";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxOtpInputModule } from 'ngx-otp-input';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    VerificationComponent,
    CompanySetupComponent
  ],
  imports: [
    NgxOtpInputModule,
    CommonModule,
    AuthenticationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    CodeInputModule.forRoot({}),
  ],
})
export class AuthenticationModule {}
