<div>
  <div class="entry-header">
    <button mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div class="title-f">Filter</div>
    <button mat-button class="reset-btn">Reset</button>
  </div>

  <div class="filter-options">
    <mat-list class="filter-list">
      <div class="xsDesc-f">Date</div>

      <mat-list-item>
        <div class="xsTitle-f" fxFlex>Start Date</div>
        <mat-form-field class="date-input">
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate />
            <input matEndDate />
          </mat-date-range-input>

          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <div class="xsTitle-f" fxFlex>End Date</div>
        <mat-datepicker-toggle
          class="date-input-icon"
          matSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
      </mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>

    <mat-selection-list class="filter-list" disableRipple>
      <div class="xsDesc-f">Status</div>
      <mat-list-option>
        <div class="xsTitle-f" fxFlex>Receipt</div>
      </mat-list-option>
      <mat-list-option>
        <div class="xsTitle-f" fxFlex>Payment</div>
      </mat-list-option>
    </mat-selection-list>
    <mat-divider></mat-divider>

    <mat-expansion-panel class="filter-list">
      <mat-expansion-panel-header>
        <mat-panel-title class="xsDesc-f"> Bank </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list disableRipple>
        <mat-list-option>
          <div class="xsTitle-f" fxFlex>Maybank</div>
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>

    <mat-divider></mat-divider>
    <mat-radio-group class="filter-radio">
      <mat-list class="filter-list">
        <div class="xsDesc-f">Sort By</div>
        <button mat-button class="sm sort-btn">
          <mat-icon>south</mat-icon>
          ASC
        </button>
        <!-- <button mat-button class="sm sort-btn">
        <mat-icon>north</mat-icon>
        DESC
      </button> -->

        <mat-list-item>
          <div class="xsTitle-f" fxFlex>Date</div>
          <mat-radio-button value="1" color="primary"></mat-radio-button>
        </mat-list-item>
        <mat-list-item>
          <div class="xsTitle-f" fxFlex>Quotation No.</div>
          <mat-radio-button value="2" color="primary"></mat-radio-button>
        </mat-list-item>

        <mat-list-item>
          <div class="xsTitle-f" fxFlex>Customer Name</div>
          <mat-radio-button value="3" color="primary"></mat-radio-button>
        </mat-list-item>
        <mat-list-item>
          <div class="xsTitle-f" fxFlex>Status</div>
          <mat-radio-button value="4" color="primary"></mat-radio-button>
        </mat-list-item>
      </mat-list>
    </mat-radio-group>
  </div>

  <div class="filter-btn-section">
    <button mat-flat-button class="bottom-sheet-btn">Show Results</button>
  </div>
</div>
