import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardDataService {
  private basePath: string = `${environment.url}/api/Dashboard/`;
  constructor(private http: HttpClient) {}
  getCashFlowSummary(value: any) {
    return this.http.get<{
      totalCashFlow: number;
      totalPayment: number;
      totalReceipt: number;
      receiptList: {
        date: Date;
        label: string;
        total: number;
      }[];
      paymentList: { date: Date; label: string; total: number }[];
    }>(`${this.basePath}GetCashFlowSummary?type=${value}`);
  }

  getAccountSummary(code: string, value: any) {
    return this.http.get<{
      total: number;
      data: {
        date: Date;
        label: string;
        total: number;
      }[];
      paymentList: { date: Date; label: string; total: number }[];
    }>(`${this.basePath}GetAccountSummaryByCode?type=${value}&code=${code}`);
  }
}
