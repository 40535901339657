import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material-module";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AuthenticationModule } from "./authentication/authentication.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AppFullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/header/header.component";
import { AppDrawerComponent } from "./layouts/drawer/drawer.component";
import { ConfirmationDialog } from "./share/dialog/confirmation-dialog/confirmation.dialog";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

import { HttpRequestInterceptor } from "./share/service/http.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SingleTextInputDialog } from "./share/dialog/single-text-input-dialog/single-text-input-dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppDrawerDirective } from './share/directive/app-drawer.directive';
import { NgxSpinnerModule } from "ngx-spinner";
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from "angularx-social-login";
import { ItemPicker } from "./share/bottom-sheet/item-picker/item-picker";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { CustomerDetailSheet } from "./share/bottom-sheet/supplier-customer-sheet/customer-detail-sheet";
import { SupplierDetailSheet } from "./share/bottom-sheet/supplier-customer-sheet/supplier-detail-sheet";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { CustomDirectiveModule } from "./share/directive/custom-directive.module";
import { ListingFilter } from "./share/bottom-sheet/listing-filter/listing-filter";
import { NgxMaskModule } from "ngx-mask";
import { TextareaInputDialog } from "./share/dialog/textarea-input-dialog/textarea-input-dialog";
import { APP_DATE_FORMATS, AppDateAdapter } from "./share/adapter/app-date.adapter";
import { ExpirationDialog } from "./share/dialog/expiration-dialog/expiration-dialog";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { InvoiceItemPicker } from "./common/invoice-item-picker/invoice-item-picker";

@NgModule({
    declarations: [
        AppComponent,
        AppBlankComponent,
        AppFullComponent,
        AppHeaderComponent,
        AppDrawerComponent,
        ConfirmationDialog,
        SingleTextInputDialog,
        TextareaInputDialog,
        ExpirationDialog,
        ItemPicker,
        ListingFilter,
        CustomerDetailSheet,
        SupplierDetailSheet,
        AppDrawerDirective,
        InvoiceItemPicker
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FlexLayoutModule,
        AuthenticationModule,
        DashboardModule,
        HttpClientModule,
        NgxSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        SocialLoginModule,
        NgxMatSelectSearchModule,
        NgxSkeletonLoaderModule,
        CustomDirectiveModule,
        NgxMaskModule.forRoot(),
        ServiceWorkerModule.register("/ngsw-worker.js", {
            enabled: environment.production,
        }),
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "en-MY" },
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'dropdownPanelClass', disableOptionCentering: true }
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.googleLoginProviderId)
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebookLoginProviderId)
                    }
                ]
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
