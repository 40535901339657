import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxOtpInputConfig } from "ngx-otp-input";
import { NgxSpinnerService } from "ngx-spinner";
import { MemberDataService } from "src/app/share/data-service/member.data.service";
import { AlertService } from "src/app/share/service/alert.service";
import { UserService } from "src/app/share/service/user.service";


@Component({
  selector: "app-verification",
  templateUrl: "./verification.html",
})
export class VerificationComponent {
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    numericInputMode: true,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  
  cipherText: string;
  email: string;
  sec = 0;
  interval;
  constructor(
    activatedRoute: ActivatedRoute,
    private userService: UserService,
    private alertService: AlertService,
    private ngxSpinnerService: NgxSpinnerService,
    private memberDataService: MemberDataService) {
    this.cipherText = activatedRoute.snapshot.queryParams.email;
    if (!this.cipherText) {
      this.alertService.openSnackBar("Invalid Request");
      location.href = "/";
    }
    else {
      this.decodeEmail();
    }

  }

  private decodeEmail() {
    this.ngxSpinnerService.show();
    this.memberDataService.decodeEmail(this.cipherText).subscribe({
      next: (x) => {
        this.email = x.email;
        this.ngxSpinnerService.hide();

      },
      error: (err) => {
        location.href = "/";
        this.alertService.openSnackBar(err.error);
      }
    });
  }
  resend() {
    this.ngxSpinnerService.show();
    this.memberDataService.resend(this.cipherText).subscribe({
      next: () => {
        this.alertService.openSnackBar("Please check email for the lastest code");
        this.sec=60;
        this.interval = setInterval(() => {
          this.sec--;
          if (this.sec == 0) {
            clearInterval(this.interval);
          }
        }, 1000)

        this.ngxSpinnerService.hide();

      },
      error: (err) => {
        location.href = "/";
        this.alertService.openSnackBar(err.error);
      }
    });
  }

  onCodeCompleted(code: string) {
    this.ngxSpinnerService.show();
    this.memberDataService.emailVerify(this.cipherText, code).subscribe({
      next: (x) => {
        this.alertService.openSnackBar("This account has been verified");
        this.userService.loginGoTo(x);

        this.ngxSpinnerService.hide();

      },
      error: (err) => {
        this.ngxSpinnerService.hide();

        this.alertService.openSnackBar(err.error);
      }
    });
  }



}
