import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyDataService } from "../data-service/company.data.service";
import { MemberDataService } from "../data-service/member.data.service";
import { RoleDataService } from "../data-service/role.data.service";
import { CompanyListingInterface } from "../interface/company.interface";
import { LoginInterface } from "../interface/login.interface";
import { MemberProfileInterface } from "../interface/member.interface";
import { AlertService } from "./alert.service";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private key = "Bhs6bPxcki";

    private loginInfoKey = "pw_uid";

    user: MemberProfileInterface;
    companyList: CompanyListingInterface[] = [];
    selectedCompany: CompanyListingInterface;
    allowAddCompany: boolean;

    acl: any = {};

    constructor(
        private ngxSpinnerService: NgxSpinnerService,
        private authService: AuthService,
        private memberDataService: MemberDataService,
        private companyDataService: CompanyDataService,
        private roleDataService: RoleDataService,
        private alertService: AlertService,
        private router: Router) {
    }
    onInit(): void {
        this.getProfile();
        this.getCompanyList();
        this.getAcl();
    }

    getCompanyList() {
        this.allowAddCompany = false;
        this.companyList = [];
        this.selectedCompany = null;
        this.companyDataService.getCompanyList().subscribe({
            next: (x) => {
                let companyId = this.authService.getCompanyId();
                this.companyList = x;
                this.selectedCompany = x.find(y => y.companyId == companyId);
                this.getCompanySetting()
            },
            error: (err) => {
                this.alertService.openSnackBar(err.error);
            }
        });

        this.companyDataService.isAllowAddCompany().subscribe({
            next: () => {
                this.allowAddCompany = true;
            }
        });
    }

    getStartDate() {
        if (!this.selectedCompany) return null;
        if (this.selectedCompany.yearEndDate >= this.selectedCompany.conversionDate) return this.selectedCompany.yearEndDate;
        if (this.selectedCompany.yearEndDate < this.selectedCompany.conversionDate) return this.selectedCompany.conversionDate;
    }

    getCompanySetting() {
        this.ngxSpinnerService.show();
        this.companyDataService.getSetting().subscribe({
            next: (x) => {
                this.ngxSpinnerService.hide();

                this.selectedCompany.yearEndDate = x.firstYear.startDate;
                this.selectedCompany.currentEndYearEnd = x.currentYearEnd.endDate;
                this.selectedCompany.currentStartYearEnd = x.currentYearEnd.startDate;
                this.selectedCompany.conversionDate = x.startDate;
            },
            error: (err) => {
                this.ngxSpinnerService.hide();
                this.alertService.openSnackBar(err.error);
            }
        });
    }

    getProfile() {
        this.memberDataService.getProfile().subscribe({
            next: (x) => {
                this.user = x;
            },
            error: (err) => {
                this.alertService.openSnackBar(err.error);
            }
        });
    }

    getAcl() {
        this.roleDataService.getACL().subscribe({
            next: (x) => {
                this.acl = x;
            },
            error: (err) => {
                this.alertService.openSnackBar(err.error);
            }
        });
    }

    rememberLoginInfo(value: any) {

        let str = CryptoJS.AES.encrypt(JSON.stringify(value), this.key).toString();
        localStorage.setItem(this.loginInfoKey, str);
    }
    resetLoginInfo() {
        localStorage.removeItem(this.loginInfoKey);
    }

    getLoginInfo(): any | undefined {
        let text = localStorage.getItem(this.loginInfoKey);
        if (!text) return undefined;
        try {
            let bytes = CryptoJS.AES.decrypt(text, this.key);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            console.log(error);
            return undefined;
        }
    }

    login(token: string) {
        this.authService.setToken(token);
    }

    loginGoTo(data: LoginInterface) {
        if (data.isNotVerified) {
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    "email": data.key
                }
            };

            this.router.navigate(["/authentication/verification"], navigationExtras);
        }
        else {
            if (!data.isSuccess) {
                this.router.navigate(["/authentication/company-setup", { key: data.key }]);
            }
            else {
                this.login(data.key);
                location.href = '/';
            }
        }
    }
}