import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { LoginInterface } from "../interface/login.interface";
import { CompanyListingInterface, CompanyProfileInterface } from "../interface/company.interface"

@Injectable({
    providedIn: 'root',
})
export class CompanyDataService {

    private basePath: string = `${environment.url}/api/Company/`;
    constructor(private http: HttpClient) {
    }

    switchCompany(id: string) {
        return this.http.get(`${this.basePath}SwitchCompany/${id}`, { responseType: 'text' });
    }

    getCompanyTypeList() {
        return this.http.get<string[]>(`${this.basePath}GetCompanyTypeList`);
    }

    isAllowSetup(cipherText: string) {
        return this.http.get<void>(`${this.basePath}IsAllowSetup?cipherText=${cipherText}`);
    }

    setup(cipherText: string, value: any) {
        return this.http.post<LoginInterface>(`${this.basePath}Setup?cipherText=${cipherText}`, value);
    }

    getCompanyList() {
        return this.http.get<CompanyListingInterface[]>(`${this.basePath}GetCompanyList`);
    }

    isAllowAddCompany() {
        return this.http.get(`${this.basePath}IsAllowAddCompany`);
    }

    isAllowChangeCurrency() {
        return this.http.get<boolean>(`${this.basePath}IsAllowChangeCurrency`);
    }

    add(value: any) {
        return this.http.post<void>(`${this.basePath}Add`, value);
    }

    edit(value: any) {
        return this.http.post<void>(`${this.basePath}Edit`, value);
    }

    getProfile() {
        return this.http.get<CompanyProfileInterface>(`${this.basePath}GetProfile`);
    }

    setYearEndDate(date: Date) {
        return this.http.get(`${this.basePath}SetYearEndDate?date=${date.toISOString()}`);
    }
    setStartDate(date: Date) {
        return this.http.get(`${this.basePath}SetStartDate?date=${date.toISOString()}`);
    }

    getSetting() {
        return this.http.get<any>(`${this.basePath}GetSetting`);
    }

}
