<div class="drawer-content">
  <div class="logo-container">
    <img src="assets/image/logo.png" />
  </div>
  <mat-nav-list>
    <div class="login-user" *ngIf="userService.user">
      <img [src]="userService.user.photoUrl ?? 'assets/image/blank-user-img.png'" alt="" />
      <div class="name" [matMenuTriggerFor]="menu">
        {{ userService.user?.name }}
        <mat-icon>arrow_drop_down</mat-icon>

        <mat-menu #menu="matMenu" xPosition="before">
          <button appAppDrawer mat-menu-item routerLink="/general/edit-profile">
            Edit Profile
          </button>
          <button appAppDrawer mat-menu-item routerLink="/general/change-password"
            *ngIf="userService.user.provider == 'EMAIL'">
            Change Password
          </button>
          <button appAppDrawer mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </div>
      <div class="email">{{ userService.user?.email }}</div>
    </div>
    <mat-list-item routerLinkActive="active" appAppDrawer routerLink="dashboard">
      <mat-icon mat-list-icon>home</mat-icon>
      <div class="title" mat-line>Dashboard</div>
    </mat-list-item>

    <mat-expansion-panel *ngIf="
        userService.acl.quotation ||
        userService.acl.invoice ||
        userService.acl.creditnote ||
        userService.acl.receipt ||
        userService.acl.customer
      ">
      <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'">
        <div class="label-container">
          <div class="label-title" mat-line>SALES</div>
          <div class="label-desc" mat-line>Receivable</div>
        </div>
      </mat-expansion-panel-header>

      <mat-list-item routerLinkActive="active" routerLink="sales/quotation-list" appAppDrawer
        *ngIf="userService.acl.quotation">
        <mat-icon mat-list-icon>description</mat-icon>
        <div class="title" mat-line>Quotation</div>
        <!-- <mat-icon matTooltip="Add Quotation"  (click)="navigateToNew('/sales/new-quotation')" >add_circle</mat-icon> -->
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="sales/sales-order-list" appAppDrawer>
        <mat-icon mat-list-icon>file_copy</mat-icon>
        <div class="title" mat-line>Sales Order</div>

      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="sales/delivery-order-list" appAppDrawer>
        <mat-icon mat-list-icon>upload_file</mat-icon>
        <div class="title" mat-line>Delivery Order</div>

      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="sales/invoice-list" appAppDrawer
        *ngIf="userService.acl.invoice">
        <mat-icon mat-list-icon>request_quote</mat-icon>
        <div class="title" mat-line>Invoice</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="sales/cash-sales-list" appAppDrawer>
        <mat-icon mat-list-icon>money</mat-icon>
        <div class="title" mat-line>Cash Sales</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="sales/dn-list" appAppDrawer>
        <mat-icon mat-list-icon>sticky_note_2</mat-icon>
        <div class="title" mat-line>Debit Note</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="sales/cn-list" appAppDrawer>
        <mat-icon mat-list-icon>paid</mat-icon>
        <div class="title" mat-line>Credit Note</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="sales/receipt-list" appAppDrawer
        *ngIf="userService.acl.receipt">
        <mat-icon mat-list-icon>receipt</mat-icon>
        <div class="title" mat-line>Receipt</div>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" routerLink="sales/refund" appAppDrawer>
        <mat-icon mat-list-icon>payments</mat-icon>
        <div class="title" mat-line>Refund</div>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" routerLink="/general/customer-list" appAppDrawer
        *ngIf="userService.acl.customer">
        <mat-icon mat-list-icon>stars</mat-icon>
        <div class="title" mat-line>Customer</div>
      </mat-list-item>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="
        userService.acl.supplierinvoice ||
        userService.acl.suppliercreditnote ||
        userService.acl.supplierpayment ||
        userService.acl.supplier
      ">
      <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'">
        <div class="label-container">
          <div class="label-title" mat-line>PURCHASE</div>
          <div class="label-desc" mat-line>Payable</div>
        </div>
      </mat-expansion-panel-header>


      <mat-list-item routerLinkActive="active" routerLink="/purchases/purchase-order" appAppDrawer>
        <mat-icon mat-list-icon>file_copy</mat-icon>
        <div class="title" mat-line>Purchase Order</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/purchases/goods-received" appAppDrawer>
        <fa-icon [icon]="faTruckRampBox" mat-list-icon style="font-size: 20px;"></fa-icon>
        <div class="title" mat-line>Goods Received</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/purchases/sup-invoice-list" appAppDrawer
        *ngIf="userService.acl.supplierinvoice">
        <mat-icon mat-list-icon>request_quote</mat-icon>
        <div class="title" mat-line>Supplier Invoice</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="purchases/dn-list" appAppDrawer>
        <mat-icon mat-list-icon>sticky_note_2</mat-icon>
        <div class="title" mat-line>Supplier Debit Note</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="purchases/cn-list" appAppDrawer>
        <mat-icon mat-list-icon>paid</mat-icon>
        <div class="title" mat-line>Supplier Credit Note</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/purchases/sup-payment-list" appAppDrawer
        *ngIf="userService.acl.supplierpayment">
        <mat-icon mat-list-icon>receipt</mat-icon>
        <div class="title" mat-line>Supplier Payment</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="purchases/refund" appAppDrawer>
        <mat-icon mat-list-icon>payments</mat-icon>
        <div class="title" mat-line>Supplier Refund</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/general/supplier-list" appAppDrawer
        *ngIf="userService.acl.supplier">
        <mat-icon mat-list-icon>engineering</mat-icon>
        <div class="title" mat-line>Supplier</div>
      </mat-list-item>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="userService.acl.shoeboxdoc || userService.acl.cashbook">
      <mat-list-item routerLinkActive="active" routerLink="/general/cashbook" appAppDrawer
        *ngIf="userService.acl.cashbook">
        <mat-icon mat-list-icon>menu_book</mat-icon>
        <div class="title" mat-line>Cashbook</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/journal-entry" appAppDrawer>
        <mat-icon mat-list-icon>menu_book</mat-icon>
        <div class="title" mat-line>Journal Entry</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/bank-reconciliation" appAppDrawer>
        <mat-icon mat-list-icon>corporate_fare</mat-icon>
        <div class="title" mat-line>Bank Reconciliation</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/general/maintain-stock-value" appAppDrawer>
        <mat-icon mat-list-icon>inventory</mat-icon>
        <div class="title" mat-line>Maintain Stock Value</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/general/account-list" appAppDrawer
        *ngIf="userService.acl.coa">
        <mat-icon mat-list-icon>account_tree</mat-icon>
        <div class="title" mat-line>Chart of Account</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/general/opening-balance" appAppDrawer
        *ngIf="userService.acl.coa">
        <mat-icon mat-list-icon>account_tree</mat-icon>
        <div class="title" mat-line>Opening Balance</div>
      </mat-list-item>

      <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'">
        <div class="label-container">
          <div class="label-title" mat-line>ACCOUNT</div>
          <div class="label-desc" mat-line>Finance</div>
        </div>
      </mat-expansion-panel-header>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="userService.acl?.customer">
      <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'">

        <div class="label-container">
          <div class="label-title" mat-line>REPORT</div>
          <div class="label-desc" mat-line>Business Analytics</div>
        </div>
      </mat-expansion-panel-header>

      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'">
          <div class="label-container">
            <div class="label-title" mat-line>Account</div>
          </div>
        </mat-expansion-panel-header>

        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/profit-and-loss">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Profit & Loss</div>
        </mat-list-item>

        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/balance-sheet">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Balance Sheet</div>
        </mat-list-item>

        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/ledger">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>General Ledger</div>
        </mat-list-item>

        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/trial-balance">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Trial Balance</div>
        </mat-list-item>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'">
          <div class="label-container">
            <div class="label-title" mat-line>Receivable</div>
          </div>
        </mat-expansion-panel-header>
        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/csr">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Customer Statement</div>
        </mat-list-item>

        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/cgr">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Customer Aging</div>
        </mat-list-item>

        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/sales-document">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Sales Document</div>
        </mat-list-item>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'">
          <div class="label-container">
            <div class="label-title" mat-line>Payable</div>
          </div>
        </mat-expansion-panel-header>
        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/ssr">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Supplier Statement</div>
        </mat-list-item>

        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/sgr">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Supplier Aging</div>
        </mat-list-item>
        <mat-list-item routerLinkActive="active" appAppDrawer routerLink="/report/purchase-document">
          <mat-icon mat-list-icon>summarize</mat-icon>
          <div class="title" mat-line>Purchase Document</div>
        </mat-list-item>
      </mat-expansion-panel>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="
        userService.acl.item || userService.acl.itemgroup || userService.acl.coa
      ">
      <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'">
        <div class="label-container">
          <div class="label-title" mat-line>OTHERS</div>
          <div class="label-desc" mat-line>Business Functions</div>
        </div>
      </mat-expansion-panel-header>

      <mat-list-item routerLinkActive="active" routerLink="/general/item-list" appAppDrawer
        *ngIf="userService.acl.item">
        <mat-icon mat-list-icon>inventory</mat-icon>
        <div class="title" mat-line>Item</div>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" routerLink="/general/item-group" appAppDrawer
        *ngIf="userService.acl.itemgroup">
        <mat-icon mat-list-icon>group_work</mat-icon>
        <div class="title" mat-line>Item Group</div>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" routerLink="/general/shoebox" appAppDrawer
        *ngIf="userService.acl.shoeboxdoc">
        <mat-icon mat-list-icon>folder_open</mat-icon>
        <div class="title" mat-line>Shoebox Doc</div>
      </mat-list-item>
    </mat-expansion-panel>
  </mat-nav-list>
</div>