<div class="wrapper">
  <!-- <app-header></app-header> -->
  <mat-drawer-container class="main-content">
    <mat-drawer #drawer [autoFocus]="false" [opened]="screenWidth > 1020" [mode]="screenWidth > 768 ? 'side' : 'over'"
      class="drawer-content">
      <app-drawer></app-drawer>
    </mat-drawer>
    <mat-drawer-content>
      <app-header [hidden]="isHideHeader"></app-header>
      <div class="page-container" id="page-container">
        <router-outlet (activate)="active()"></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>