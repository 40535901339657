import { Component, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import {
    MatBottomSheetRef,
    MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { ItemDataService } from "src/app/share/data-service/item.data.service";
import { TaxDataService } from "src/app/share/data-service/tax.data.service";
import {
    ItemOptionInterface,
    ItemPickerInterface,
} from "src/app/share/interface/item.interface";
import { AlertService } from "src/app/share/service/alert.service";

import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerQuotationDataService } from "src/app/share/data-service/customer-quotation.data.service";
import { TextareaInputDialog } from "src/app/share/dialog/textarea-input-dialog/textarea-input-dialog";
import { ValidatorFunction } from "src/app/share/function/validator.function";
import { TaxOptionInterface } from "src/app/share/interface/tax.interface";
import { UserService } from "src/app/share/service/user.service";
import { AccountInterface } from "src/app/share/interface/account.interface";
import { MatSelectChange } from "@angular/material/select";

@Component({
    selector: "invoice-item-picker",
    templateUrl: "invoice-item-picker.html",
})
export class InvoiceItemPicker {
    isItemLoading: boolean = true;
    isTaxLoading: boolean = true;

    itemList: ItemOptionInterface[] = [];
    filterItemList: ItemOptionInterface[] = [];

    taxList: TaxOptionInterface[] = [];
    filterTaxList: TaxOptionInterface[] = [];

    frmGrp: UntypedFormGroup;
    title: string;

    remark: string;

    filterAccountList: AccountInterface[] = [];
    accountList: AccountInterface[] = [];
    isSales: boolean = true;
    isPass: boolean;
    constructor(
        private activatedRoute: ActivatedRoute,
        public userService: UserService,
        private router: Router,
        private ngxSpinnerService: NgxSpinnerService,
        private customerQuotationDataService: CustomerQuotationDataService,
        private bottomSheetRef: MatBottomSheetRef<InvoiceItemPicker>,
        @Inject(MAT_BOTTOM_SHEET_DATA) private data: {
            item: ItemPickerInterface,
            accountList: AccountInterface[],
            isPass: boolean,
        },
        private alertService: AlertService,
        private itemDataService: ItemDataService,
        private taxDataService: TaxDataService,
        private dialog: MatDialog
    ) {
        this.isPass = data.isPass;
        var urlList = this.router.url.split("/");
        let path = urlList[1];
        if (path == "purchases") {
            this.isSales = false;
        }
        if (this.data) this.title = "Save";
        else this.title = "Add";

        this.remark = data?.item?.remark;
        this.initForm();

        this.getItem();
        this.getIax();
        if ((this.data?.item?.documentId || this.data?.item?.isTransfer)) {
            this.frmGrp.controls.name.disable();
            if (this.data?.item?.isTransfer) {
                this.frmGrp.controls.qty.addValidators([Validators.min(this.data?.item?.transferQty)])
            }
        }
    }

    private initForm() {
        let type = "Sales";
        if (!this.isSales) type = "Purchase";
        if (this.data?.item?.type) {
            type = this.data?.item?.type;
        }
        this.frmGrp = new UntypedFormGroup({
            id: new UntypedFormControl(this.data?.item?.itemId),
            name: new UntypedFormControl(this.data?.item?.name, []),
            qty: new UntypedFormControl(this.data?.item?.qty || 1, [
                Validators.required,
                ValidatorFunction.notPositive(),
            ]),
            rate: new UntypedFormControl(this.data?.item?.rate, [
                Validators.required
            ]),
            isPercentage: new UntypedFormControl(this.data?.item?.isPercentage ?? false),
            discount: new UntypedFormControl(this.data?.item?.discount, [
                ValidatorFunction.notNegative(),
            ]),
            taxId: new UntypedFormControl(this.data?.item?.taxId),
            accountId: new UntypedFormControl(this.data?.item?.accountId, [Validators.required]),
            type: new UntypedFormControl(type),
        });
        if (this.isPass) {
            this.frmGrp.controls.accountId.disable();
            this.frmGrp.controls.name.setValidators([Validators.required]);
            this.frmGrp.controls.name.updateValueAndValidity();
        }
        this.frmGrp.controls.name.valueChanges.subscribe((x) => {
            if (typeof x == "string") this.filterItem(x);
        });
        this.getAccountByType(type);
    }

    private getItem() {
        this.itemDataService.getOption().subscribe({
            next: (x) => {
                this.isItemLoading = false;
                this.itemList = x;
                if (this.data?.item?.itemId) {
                    this.frmGrp.controls.name.setValue(
                        this.itemList.find((y) => y.id == this.data?.item?.itemId)
                    );
                }
                this.filterItem();
            },
            error: (err) => {
                this.isItemLoading = false;
                this.alertService.openSnackBar(err.error);
            },
        });
    }

    private getIax() {
        this.taxDataService.getOption().subscribe({
            next: (x) => {
                this.isTaxLoading = false;
                this.taxList = x;
                this.filterTax();
            },
            error: (err) => {
                this.isTaxLoading = false;
                this.alertService.openSnackBar(err.error);
            },
        });
    }

    displayFnItem(item: any): string {
        if (!item) return null;
        if (typeof item == "string") return item;
        return `${item.code} - ${item.name}`;
    }

    onDiscountChange(val: boolean) {
        this.frmGrp.controls.discount.clearValidators();
        if (val) {
            this.frmGrp.controls.discount.setValidators([
                Validators.min(0),
                Validators.max(100),
            ]);
        } else {
            this.frmGrp.controls.discount.setValidators([
                ValidatorFunction.notNegative(),
            ]);
        }
        this.frmGrp.controls.discount.updateValueAndValidity();
    }

    filterItem(val?: string) {
        if (!val) this.filterItemList = Object.assign([], this.itemList);
        else {
            val = val.trim().toLowerCase();
            this.filterItemList = Object.assign(
                [],
                this.itemList.filter(
                    (x) =>
                        x.name.toLowerCase().includes(val) ||
                        x.code.toLowerCase().includes(val)
                )
            );
        }
    }

    filterTax(val?: string) {
        if (!val) this.filterTaxList = Object.assign([], this.taxList);
        else {
            val = val.trim().toLowerCase();
            this.filterTaxList = Object.assign(
                [],
                this.taxList.filter(
                    (x) =>
                        x.name.toLowerCase().includes(val) ||
                        x.rate.toString().toLowerCase().includes(val)
                )
            );
        }
    }

    clearTax() {
        event.stopImmediatePropagation();
        this.frmGrp.controls.taxId.setValue(null);
    }
    getUom() {
        if (this.frmGrp?.value?.id) {
            let item = this.itemList.find((x) => x.id == this.frmGrp.value.id);
            return item?.uom;
        } else return null;
    }

    clearItem() {
        event.stopImmediatePropagation();
        if (this.frmGrp.controls.name.disabled) return
        this.frmGrp.controls.name.setValue(null);
        this.frmGrp.controls.id.setValue(null);
        this.frmGrp.controls.rate.setValue(null);
        this.frmGrp.controls.taxId.setValue(null);
    }

    itemChange(val: MatAutocompleteSelectedEvent) {

        let isSale = window.location.pathname.split("/")[1] == "sales";
        let value = val.option.value;
        this.frmGrp.controls.type.setValue("Select from GL");
        this.changeType({
            value: "Select from GL"
          } as any);
        if (isSale) {
            this.frmGrp.controls.rate.setValue(value.price);
            this.frmGrp.controls.accountId.setValue(value.salesAccountId);

        }
        else{
            this.frmGrp.controls.rate.setValue(value.cost);
            this.frmGrp.controls.accountId.setValue(value.purchaseAccountId);
        }

        this.frmGrp.controls.id.setValue(value.id);
        this.frmGrp.controls.taxId.setValue(value.taxId);
    }

    getDiscountAmt() {
        if (this.frmGrp.invalid) return 0;
        let val = this.frmGrp.value;
        let subTotal = val.qty * val.rate;
        let discountAmt = val.discount || 0;
        if (val.isPercentage) {
            return subTotal * (discountAmt / 100);

        }
        return discountAmt;
    }

    getSubTotal() {
        let val = this.frmGrp.value;
        let subTotal = val.qty * val.rate;
        let discountAmt = this.getDiscountAmt();
        if (subTotal < discountAmt) {
            setTimeout(() => {
                this.frmGrp.controls.discount.setValue(null);
                this.frmGrp.controls.discount.updateValueAndValidity();
                discountAmt = 0;
            }, 0);
        }

        return subTotal - discountAmt;
    }

    getTaxAmt() {
        if (this.frmGrp.invalid) return 0;
        let val = this.frmGrp.value;
        let total = this.getSubTotal();

        if (val.taxId) {
            let tax = this.taxList.find((x) => x.id == val.taxId);
            return total - total * ((100 - tax?.rate) / 100);
        }
        return 0;
    }

    getTotal() {
        if (this.frmGrp.invalid) return 0;
        let total = this.getSubTotal();
        let taxAmt = this.getTaxAmt();
        return total + taxAmt;
    }

    submit() {
        if (this.frmGrp.invalid) return;
        if (this.data?.item?.transferItemId && this.data?.item?.transferItemId != '00000000-0000-0000-0000-000000000000') {
            this.checkBalance();
        }
        else
            this.save();
    }

    private checkBalance() {
        let id: string;
        if (this.router.url.includes("/sales/edit-")) {
            id = this.activatedRoute.snapshot.queryParams.id;
        }
        let val = this.frmGrp.getRawValue();

        let data = {
            transferId: this.data?.item?.documentId,
            transferItemId: this.data?.item?.transferItemId,
            transferFrom: this.data?.item?.document,
            qty: val.qty,
            id
        };
        this.ngxSpinnerService.show();
        this.customerQuotationDataService.checkBalance(data).subscribe({
            next: () => {
                this.ngxSpinnerService.hide();

                this.save();
            },
            error: (err) => {
                this.ngxSpinnerService.hide();
                this.alertService.openSnackBar(err.error);
            },
        })
    }


    private save() {
        let val = this.frmGrp.getRawValue();

        let item = this.itemList.find((x) => x.id == val.id);
        let acc = this.data.accountList.find(x => x.id == val.accountId);
        if (this.data?.item?.isTransfer) {
            this.data.item.isFullTransfer = this.data?.item?.transferQty >= val.qty;
        }
        let result: ItemPickerInterface = {
            itemId: item?.id,
            name: item?.name ?? val.name,
            code: item?.code,
            rate: val.rate,
            qty: val.qty,
            discount: val.discount,
            taxId: val.taxId,
            uom: item?.uom,
            taxRate: 0,
            total: 0,
            isPercentage: val.isPercentage,
            remark: this.remark,
            document: this.data?.item?.document,
            documentId: this.data?.item?.documentId,
            documentNo: this.data?.item?.documentNo,
            // transferId: this.data?.transferId,
            // transferFrom: this.data?.transferFrom,
            // transferNo: this.data?.transferNo,
            transferItemId: this.data?.item?.transferItemId,
            transferQty: this.data?.item?.transferQty,
            isTransfer: this.data?.item?.isTransfer,
            isFullTransfer: this.data?.item?.isFullTransfer,
            accountId: acc?.id,
            accountName: acc?.name,
            type: val.type,
            accountCode: acc?.code,
        };
        if (val?.taxId) {
            let tax = this.taxList.find((x) => x.id == val?.taxId);
            result.taxRate = tax?.rate;
        }
        result.total = this.getTotal();
        if (this.data) {
            result.id = this.data?.item?.id;
        }

        this.bottomSheetRef.dismiss(result);
    }

    itemDescDialog() {
        let dialogRef = this.dialog.open(TextareaInputDialog, {
            data: this.remark,
        });
        dialogRef.afterClosed().subscribe((x) => {
            if (typeof x == "object") {
                this.remark = x.val;
            }
        });
    }
    private getAccountByType(type: string) {
        if (type == "Sales") {
            this.accountList = Object.assign([], this.data.accountList.filter(x => x.specialType == "SL"));
            if (!this.frmGrp.value.accountId && this.accountList.length == 1) {
                this.frmGrp.controls.accountId.setValue(this.accountList[0].id);
            }
        }
        else if (type == "Purchase") {
            this.accountList = Object.assign([], this.data.accountList.filter(x => x.specialType == "CO"));
            if (!this.frmGrp.value.accountId && this.accountList.length == 1) {
                this.frmGrp.controls.accountId.setValue(this.accountList[0].id);
            }
        }
        else this.accountList = Object.assign([], this.data.accountList);
        this.filterAccountList = Object.assign([], this.accountList);

    }

    changeType(e: MatSelectChange) {
        this.frmGrp.controls.accountId.setValue(null);
        this.getAccountByType(e.value);
    }

    filterAccount(txt?: string) {
        let list: AccountInterface[] = Object.assign([], this.accountList);
        if (txt) {
            txt = txt.trim().toLowerCase();
            list = list.filter(
                (x) =>
                    x.code.toLowerCase().includes(txt) ||
                    x.name.toLowerCase().includes(txt)
            );
        }
        this.filterAccountList = list;
    }
}
