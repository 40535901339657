<div>
  <div class="bs-grey"></div>
  <mat-card class="card-details profile-card" *ngIf="!data">
    <ngx-skeleton-loader
      [theme]="{
        'border-radius': '15px',
         height: '100px',
        'animation-duration': '1s'
      }"
    >
    </ngx-skeleton-loader>
  </mat-card>
  <ng-container *ngIf="data">
    <mat-card class="card-details profile-card">
      <div class="content-wrapper">
        <div class="text-center">
          <img
            class="profile-img"
            [src]="data?.profileImage ?? defaultImage"
            alt=""
          />

          <div class="mdTitle-f m-b-10">{{data?.name }}</div>
          <div class="icon-text-wrapper m-b-10">
            <span class="icon-text" *ngIf="data?.mobileNo">
              <mat-icon>call</mat-icon>
              <span> {{data?.mobileNo }}</span>
            </span>
            <span class="icon-text" *ngIf="data?.email">
              <mat-icon>email</mat-icon>
              <span> {{data?.email}} </span>
            </span>
          </div>
          <div class="icon-text-wrapper" *ngIf="data?.address">
            <span class="icon-text show-overflow">
              <mat-icon>place</mat-icon>
              <span> {{data?.address}} </span>
            </span>
          </div>
          <span class="icon-text" *ngIf="data?.paymentTerm">
            <mat-icon>payments</mat-icon>
            <span> {{data?.paymentTerm}} </span>
          </span>
          <div class="profile-func">
            <div
              matTooltip="Call"
              *ngIf="data?.mobileNo"
              [callTo]="data?.mobileNo"
            >
              <mat-icon>call</mat-icon>
            </div>
            <div matTooltip="Email" *ngIf="data?.email" [emailTo]="data?.email">
              <mat-icon>mail</mat-icon>
            </div>
            <div
              matTooltip="location"
              *ngIf="data?.address"
              (click)="openGoogleMap()"
            >
              <mat-icon>place</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-list class="card-list" *ngIf="contact">
      <mat-list-item>
        <div class="icon-text-wrapper" matLine>
          <div class="icon-text">
            <mat-icon class="color-orange" *ngIf="contact.isDefault"
              >local_police</mat-icon
            >
            <span class="xsTitle-f color-black">{{contact.name}}</span>
          </div>
        </div>
        <div class="icon-text-wrapper" matLine>
          <div class="icon-text">
            <mat-icon>call</mat-icon>
            <span>{{contact.mobileNo}}</span>
          </div>
        </div>

        <div class="icon-text-wrapper" matLine>
          <div class="icon-text">
            <mat-icon>email</mat-icon>
            <span>{{contact.email}}</span>
          </div>
        </div>
        <button
          mat-icon-button
          class="m-r-16 yellow-btn"
          [emailTo]="contact?.email"
        >
          <mat-icon>email</mat-icon>
        </button>

        <button mat-icon-button class="yellow-btn" [callTo]="contact?.mobileNo">
          <mat-icon>call</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>

    <button mat-flat-button class="bottom-sheet-btn" (click)="detail()">
      View Full Details
    </button>
    <div class="bs-black"></div>
  </ng-container>
</div>
