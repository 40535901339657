import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { finalize, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ExpirationDialog } from "../dialog/expiration-dialog/expiration-dialog";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    apiNum: number = 0;
    dialogRef: MatDialogRef<ExpirationDialog, any> = null
    constructor(private authService: AuthService, private dialog: MatDialog) { }


    intercept(req: HttpRequest<any>, next: HttpHandler) {
        try {
            let token: string = this.authService.getToken();
            if (!req.headers.has("Authorization") && token) {
                req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
            }

            if (this.apiNum == 0)
                this.apiNum++;

            return next.handle(req).pipe(
                finalize(() => {
                    --this.apiNum;
                }),
                catchError(err => {

                    console.error(err);
                    if (err.status === 401) {
                        if (err.error == "company") {
                            err.error = "Your company’s subscription has expired.";
                            if (!this.dialogRef)
                                this.dialogRef = this.dialog.open(ExpirationDialog, {
                                    disableClose: true,
                                });
                        }
                        else {
                            err.error = "Session expired";
                            this.authService.logout();
                        }

                    }
                    else if (err.status === 0) {
                        err.error = "Unable connect to server.";
                    }
                    return throwError(err);
                }),
            );
        } catch (error) {

            return next.handle(req);
        }
    }
}
