<div>
  <div mat-dialog-title>{{data?.title}}</div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <span [innerHtml]="data?.content"> </span>
    <span class="semibold-font">&nbsp;{{data?.boldContent}}</span>
    <span *ngIf="!data?.hideQuestionMark">?</span>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      class="sm"
      [mat-dialog-close]="false"
      *ngIf="!data.hideCancelBtn"
    >
      {{data?.cancelBtnText ?? "Cancel"}}
    </button>
    <button
      mat-flat-button
      [mat-dialog-close]="true"
      color='{{data?.btnColor ?? "primary"}}'
      class="sm"
    >
      {{data?.okBtnText ?? "Ok"}}
    </button>
  </div>
</div>
