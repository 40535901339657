<mat-toolbar class="page-header" style="
    display: block !important;
    background: linear-gradient(180deg, #6913cc 10%, #4743e3) !important;
  ">
  <div class="menu-container">
    <button mat-icon-button type="button" (click)="backLogin()">
      <mat-icon class="rev-icon">exit_to_app</mat-icon>
    </button>
    <span class="page-header-title">Get Started</span>
  </div>
</mat-toolbar>

<div style="background: white">
  <mat-stepper linear #stepper class="company-setup" [disableRipple]="true">
    <mat-step [stepControl]="frmGrpFirstStep">
      <form [formGroup]="frmGrpFirstStep">
        <mat-card class="form-field without-shadow">
          <mat-card-content>
            <div class="preview-img-container">
              <div class="empty-img">
                <mat-icon *ngIf="!imgSrc">landscape</mat-icon>
                <img [src]="imgSrc" *ngIf="imgSrc" />
              </div>
              <input type="file" name="pic" #Image accept="image/*" style="display: none" #fileInput
                (change)="fileChangeEvent($event)" />
              <button mat-mini-fab (click)="fileInput.click()" type="button">
                <mat-icon>photo_camera</mat-icon>
              </button>
            </div>

            <div class="inform">
              <mat-label>Base Currency *</mat-label>
              <mat-form-field appearance="outline" floatLabel="false">
                <mat-select formControlName="currency" placeholder="Select Base Currency"
                  (selectionChange)="currencyChange($event.value)">
                  <mat-select-trigger>
                    {{frmGrpFirstStep?.value?.currency?.sign}}
                  </mat-select-trigger>
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search Base Currency..." [searching]="isCurrencyLoading"
                      [ngModelOptions]="{ standalone: true }" noEntriesFoundLabel="No matching base currency found"
                      (ngModelChange)="filterCurrency($event)" ngModel ngDefaultControl>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let item of filterCurrencyList;" [value]="item">
                    <div>
                      <div>{{item.country}} - {{item.sign}}</div>
                      <div class="xsDesc-f">
                        {{item.name}}
                      </div>
                    </div>
                  </mat-option>

                </mat-select>
                <mat-error>Company Type is required</mat-error>
              </mat-form-field>
            </div>

            <div class="inform">
              <mat-label>Base Currency Description</mat-label>
              <mat-form-field appearance="outline" floatLabel="false">
                <input matInput [placeholder]="frmGrpFirstStep?.value?.currency?.name"
                  formControlName="currencyDescription" autocomplete="off" />
              </mat-form-field>
            </div>

            <div class="inform mobile-full" *ngIf="frmGrpFirstStep?.value?.currency?.country == defaultCountry">
              <mat-label>Company Type</mat-label>
              <mat-form-field appearance="outline" floatLabel="false">
                <mat-select required formControlName="type" placeholder="Select Company Type"
                  (selectionChange)="changeType($event.value)">
                  <mat-select-trigger>
                    {{frmGrpFirstStep?.value.type}}
                  </mat-select-trigger>
                  <mat-option *ngFor="let item of companyTypeList;let last = last;" [value]="item">
                    <div>
                      <div>{{item}}</div>
                      <div class="xsDesc-f" *ngIf="last">
                        Eg: Freelancer, Agent, Broker
                      </div>
                    </div>
                  </mat-option>
                </mat-select>
                <mat-error>Company Type is required</mat-error>
              </mat-form-field>
            </div>

            <div class="inform mobile-full">
              <mat-label>{{label}} Name *</mat-label>
              <mat-form-field appearance="outline" floatLabel="false">
                <input matInput [placeholder]="'Enter '+label+' Name'" required formControlName="name" />
                <mat-error>{{label}} Name is required</mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="form-submit" style="padding-top:16px !important;padding-right: 16px">
          <button class="save full-width" matStepperNext mat-flat-button>
            Next
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="frmGrpSecondStep">
      <form [formGroup]="frmGrpSecondStep">
        <mat-card class="form-field without-shadow">
          <div class="cover-label grey" style="display: flex; align-items: center; margin-bottom: 16px">
            <mat-icon style="margin-right: 8px">info</mat-icon>
            You can complete your {{label | lowercase}} profile in setting
            later.
          </div>

          <mat-card-content>
            <ng-container *ngIf="label">
              <div class="inform mobile-full">
                <mat-label>Registration No.</mat-label>
                <mat-form-field appearance="outline" floatLabel="false">
                  <input matInput placeholder="eg. STE-1223" formControlName="regNo" />
                </mat-form-field>
              </div>

              <!-- <div class="inform mobile-full">
                <mat-label>Register Date</mat-label>
                <mat-form-field appearance="outline" floatLabel="false">
                  <input matInput placeholder="Select Register Date" formControlName="regDate" readonly
                    (click)="picker1.open()" [max]="maxDate" [matDatepicker]="picker1" />
                  <mat-datepicker #picker1></mat-datepicker>
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                </mat-form-field>
              </div> -->
            </ng-container>
            <div class="inform mobile-full">
              <mat-label>{{label}} Address</mat-label>
              <mat-form-field appearance="outline" floatLabel="false">
                <textarea matInput placeholder="Type address here ..." cdkTextareaAutosize cdkAutosizeMinRows="4"
                  cdkAutosizeMaxRows="6" formControlName="address"></textarea>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="form-submit" style="padding-top:16px !important;padding-right: 16px">
          <button class="save full-width" (click)="save()" mat-flat-button>
            Complete
          </button>
          <button class="draft full-width" type="button" mat-button (click)="back()">
            Back
          </button>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</div>