<mat-toolbar class="header" [ngClass]="{'show-tablet': header=='hidden'}">
  <div class="menu-container">
    <button mat-icon-button (click)="drawerService.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="page-title show-mobile" fxFlex>{{ title }}</span>
    <span class="page-title show-tablet" fxFlex>Welcome to Shoebox</span>

    <div class="current-company" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
      <img class="medium-avatar" *ngIf="userService.selectedCompany"
        [src]="userService.selectedCompany.photoUrl ?? defaultCompanyImage" alt="" />

      <img src="./assets/image/crown.svg" style="margin-left: 16px; margin-right: 4px"
        *ngIf="userService.selectedCompany?.package == 'UNLIMITED'" alt="" />

      <mat-icon *ngIf="userService.selectedCompany?.isExpired" class="color-red">event_busy</mat-icon>

      <span *show="'not-mobile'">{{ userService.selectedCompany?.name }}</span>
    </div>

    <button mat-icon-button class="show-tablet head-setting" routerLink="/settings/menu">
      <mat-icon>settings</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item fxLayoutAlign=" center" *ngFor="let item of userService.companyList"
        (click)="switchCompany(item.companyId)">
        <img class="company-avatar" [src]="item.photoUrl ?? defaultCompanyImage" alt=""
          [class.active]="item.companyId == authService.getCompanyId()" />

        <img src="./assets/image/crown.svg" style="margin-right: 10px" *ngIf="item?.package == 'UNLIMITED'" alt="" />

        <span fxFlex> <mat-icon *ngIf="item?.isExpired">event_busy</mat-icon>{{ item.name }}</span>
        <button mat-icon-button class="company-setting-icon show-mobile" (click)="switchCompany(item.companyId, true)">
          <mat-icon>settings</mat-icon>
        </button>
      </button>
      <button mat-menu-item routerLink="/settings/new-company" *ngIf="userService.allowAddCompany">
        <mat-icon>add</mat-icon>
        <span>Add Company</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>