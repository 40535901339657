import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { TaxListingInterface,TaxOptionInterface } from "../interface/tax.interface";


@Injectable({
    providedIn: 'root',
})
export class TaxDataService {
    private basePath: string = `${environment.url}/api/Tax/`;
    constructor(private http: HttpClient) {
    }

    add(value: any) {
        return this.http.post<void>(`${this.basePath}Add`, value);
    }

    edit(id: string, value: any) {
        return this.http.post<void>(`${this.basePath}Edit/${id}`, value);
    }

    delete(id: string) {
        return this.http.get<void>(`${this.basePath}delete/${id}`);
    }

    SetDefault(id: string) {
        return this.http.get<void>(`${this.basePath}SetDefault/${id}`);
    }
    getListing() {
        return this.http.get<TaxListingInterface[]>(`${this.basePath}GetListing`);
    }
    getOption() {
        return this.http.get<TaxOptionInterface[]>(`${this.basePath}GetListing`);
    }

}