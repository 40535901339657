<h2 mat-dialog-title>Subscription Expired</h2>
<mat-dialog-content>

    <p>Your company's subscription has expired. To continue using our services, please renew your subscription.</p>


    <p>For further assistance, contact our support team at <a href='mailto:support@shoebox.my'>support@shoebox.my</a>
    </p>
    <ng-container *ngIf="!isDisplay">
        <ng-container *ngIf="getCompanyList()?.length!=0">
            <p>Switch to other company ?</p>

            <mat-form-field class="full-width">
                <mat-label>Company</mat-label>
                <mat-select [(ngModel)]="companyId">
                    <mat-option *ngFor="let item of getCompanyList()" [value]="item.companyId">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </ng-container>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close *ngIf="isDisplay">Close</button>

    <ng-container  *ngIf="!isDisplay">
        <button mat-button color="primary" [disabled]="!companyId" *ngIf="getCompanyList()?.length!=0"
            (click)="swtichCompany()">Switch Company</button>
        <button mat-button color="warn" (click)="authService.logout()">Logout</button>

    </ng-container>
</mat-dialog-actions>