<div>
  <div mat-dialog-title>Item Description</div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="form-field">
      <mat-form-field appearance="outline" floatLabel="false">
        <textarea
          [(ngModel)]="text"
          matInput
          placeholder="Type description here ..."
          cdkTextareaAutosize
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="6"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="button" class="sm" mat-button [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      mat-flat-button
      class="sm secondary"
      [mat-dialog-close]="{val:text??null}"
    >
      Save
    </button>
  </div>
</div>
