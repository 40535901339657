import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SocialUser } from "angularx-social-login";
import { environment } from "../../../environments/environment";
import { LoginInterface } from "../interface/login.interface";
import { MemberListingInterface, MemberProfileInterface } from "../interface/member.interface";

@Injectable({
    providedIn: 'root',
})
export class MemberDataService {

    private basePath: string = `${environment.url}/api/Member/`;
    constructor(private http: HttpClient) {
    }

    login(email: string, password: string) {
        return this.http.get<LoginInterface>(`${this.basePath}Login?email=${email}&password=${password}`);
    }

    socialLogin(obj: SocialUser) {
        return this.http.post<LoginInterface>(`${this.basePath}SocialMediaLogin`, {
            id: obj.id,
            email: obj.email,
            name: obj.name,
            photoUrl: obj.photoUrl,
            provider: obj.provider,
            authToken: obj.authToken
        });
    }

    register(email: string, name: string, password: string) {
        return this.http.post<{ email: string }>(`${this.basePath}Register`, {
            email, name, password
        });
    }

    getProfile() {
        return this.http.get<MemberProfileInterface>(`${this.basePath}Profile`);

    }

    emailVerify(cipherText: string, otp: string) {
        return this.http.get<LoginInterface>(`${this.basePath}EmailVerify?cipherText=${cipherText}&otp=${otp}`);
    }

    resend(cipherText: string) {
        return this.http.get(`${this.basePath}Resend?cipherText=${cipherText}`);
    }

    sendResetPasswordByEmail(email: string) {
        return this.http.get(`${this.basePath}SendResetPasswordByEmail?email=${email}`);
    }

    resetPasswordVerify(email: string, otp: string) {
        return this.http.get<LoginInterface>(`${this.basePath}ResetPasswordVerify?email=${email}&otp=${otp}`);
    }
    resetPassword(email: string, otp: string, password: string) {
        return this.http.get<LoginInterface>(`${this.basePath}ResetPassword?email=${email}&otp=${otp}&password=${password}`);
    }

    decodeEmail(cipherText: string) {
        return this.http.get<{ email: string }>(`${this.basePath}GetEmail?cipherText=${cipherText}`);
    }

    invite(roleId: string, email: string) {
        return this.http.get(`${this.basePath}invite?roleId=${roleId}&email=${email}`);
    }

    getListing() {
        return this.http.get<MemberListingInterface[]>(`${this.basePath}GetListing`);
    }

    changeRole(memberId: string, roleId: string) {
        return this.http.get(`${this.basePath}ChangeRole?roleId=${roleId}&memberId=${memberId}`);
    }

    deleteUser(id: string) {
        return this.http.get(`${this.basePath}DeleteUser/${id}`);
    }

    changeStatus(id: string) {
        return this.http.get(`${this.basePath}changeStatus/${id}`);
    }

    updateProfile(value: any) {
        return this.http.post(`${this.basePath}UpdateProfile`, value);
    }

    changePassword(value: any) {
        return this.http.post(`${this.basePath}ChangePassword`, value);
    }


}