<div class="login-page">
  <div class="login-form">

    <form [formGroup]="frmGrp" (ngSubmit)="signUp()">
      <div class="logo-container">
        <img src="assets/image/logo.png" class="login-logo" alt="" />
        <div>
          Manage business accounting smarter
        </div>
      </div>
      <mat-card class="form-field">

        <div class="sign-in-text">Sign Up</div>
        <mat-card-content>
          <div class="inform mobile-full">
            <mat-label>Email</mat-label>
            <mat-form-field appearance="outline" floatLabel="false">
              <input matInput placeholder="Enter your email" required formControlName="email" />
              <mat-error *ngIf="frmGrp.controls.email?.errors?.required">Email is required</mat-error>
              <mat-error *ngIf="frmGrp.controls.email?.errors?.email">Invalid email format</mat-error>
            </mat-form-field>
          </div>

          <div class="inform mobile-full">
            <mat-label>Name</mat-label>
            <mat-form-field appearance="outline" floatLabel="false">
              <input matInput placeholder="Enter your name" required formControlName="name" />
              <mat-error>Name is required</mat-error>
            </mat-form-field>
          </div>
          <div class="inform mobile-full">
            <mat-label>Password</mat-label>
            <mat-form-field appearance="outline" floatLabel="false">
              <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="Enter your password"
                formControlName="password" required />
              <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                <mat-icon>{{ hidePassword ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
              <mat-error *ngIf="frmGrp.controls.password?.errors?.required">Password is required</mat-error>
              <mat-error *ngIf="frmGrp.controls.password?.errors?.pattern">Password minimum 8 characters, at least
                one uppercase letter, one lowercase letter, one number and one special character</mat-error>
            </mat-form-field>
          </div>

          <div class="inform mobile-full">
            <mat-label>Confirm Password</mat-label>
            <mat-form-field appearance="outline" floatLabel="false">
              <input matInput [type]="hideConfrimPassword ? 'password' : 'text'" placeholder="Enter your password"
                formControlName="confrimPassword" required />
              <button type="button" mat-icon-button matSuffix (click)="hideConfrimPassword = !hideConfrimPassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfrimPassword">
                <mat-icon>{{ hideConfrimPassword ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
              <mat-error *ngIf="frmGrp.controls.confrimPassword?.errors?.required">Confrim Password is required
              </mat-error>
              <mat-error *ngIf="frmGrp.controls.confrimPassword?.errors?.match">Confrim Password not match with password
              </mat-error>
            </mat-form-field>
          </div>


          <div>

            <section>
              <mat-checkbox color="primary" formControlName="agree">I agree to the <u
                  (click)="openTermsOfService()">Terms of Service</u> and <u (click)="openPrivacyPolicy()"> Privacy
                  Policy</u></mat-checkbox>
            </section>
          </div>
          <button mat-raised-button color="primary" class="lg">Sign Up</button>
          <div class="sign-up-account">
            Already have account? <span routerLink="/authentication/login">Login</span>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
  <!-- <div class="crm-info">
    <div>
      <div class="title">Welcome to BEYONARY ADMIN</div>
      <div class="desc">
        Turn your sales process into a state of the art, revenue-generating
        machine. Manage Your Pipeline with Total Visibility
      </div>
    </div>
  </div> -->
</div>