import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component'

const routes: Routes = [{
    path: '',
    children: [
        {
            path: '',
            component: DashboardComponent,
            data: { title: "Dashboard", header: "show" }

        },
    ]
},];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
