import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { UserService } from "../../share/service/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from "../../share/service/alert.service";
import { MemberDataService } from "../../share/data-service/member.data.service";
import { SocialAuthService } from "angularx-social-login";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  hide: boolean = true;
  formGroup: UntypedFormGroup;
  socialAuth: boolean = false;

  constructor(
    private socialAuthService: SocialAuthService,
    private userService: UserService,
    private ngxSpinnerService: NgxSpinnerService,
    private memberDataService: MemberDataService,
    private alertService: AlertService
  ) {
    this.socialAuthService.initState.toPromise().then((x) => {
      this.socialAuth = x;
    });
  }

  ngOnInit(): void {
    let info = this.userService.getLoginInfo();
    this.formGroup = new UntypedFormGroup({
      userName: new UntypedFormControl(info?.userName ?? null, [Validators.required]),
      password: new UntypedFormControl(info?.password ?? null, [Validators.required]),
      remember: new UntypedFormControl(info ? true : false),
    });
  }

  socialLogin(providerId: string) {
    this.ngxSpinnerService.show();
    this.socialAuthService
      .signIn(providerId)
      .then((x) => {
        this.memberDataService.socialLogin(x).subscribe({
          next: (x) => {
            this.ngxSpinnerService.hide();
            this.userService.loginGoTo(x);
          },
          error: (err) => {
            this.alertService.openSnackBar(err.error);
            this.ngxSpinnerService.hide();
          },
        });
      })
      .catch(() => {
        this.ngxSpinnerService.hide();
      });
  }

  login() {
    if (this.formGroup.invalid) return;

    this.ngxSpinnerService.show();
    let value = this.formGroup.value;

    this.memberDataService.login(value.userName, value.password).subscribe({
      next: (x) => {
        if (value.remember)
          this.userService.rememberLoginInfo({
            userName: value.userName,
            password: value.password,
          });
        else this.userService.resetLoginInfo();

        this.ngxSpinnerService.hide();
        this.userService.loginGoTo(x);
      },
      error: (err) => {
        this.ngxSpinnerService.hide();
        this.alertService.openSnackBar(err.error);
      },
    });
  }
}
