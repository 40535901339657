<mat-toolbar class="page-header"
    style="display: block !important;background: linear-gradient(180deg, #6913CC 10%, #4743E3) !important;">
    <div class="menu-container">
        <button mat-icon-button type="button">
            <mat-icon style="color: white">chevron_left</mat-icon>
        </button>
        <span class="page-header-title">Verification</span>
    </div>
</mat-toolbar>

<div class="verification-container">
    <div style="max-width: 600px;margin: 0 auto;">
        <div class="heading-lg text-center mb-12">
            Email Verification
        </div>
        <div class="verification-email">
            A 4 digit code has sent to <b>{{email}}</b>
        </div>
        <!-- 
        <code-input [codeLength]="4" (codeCompleted)="onCodeCompleted($event)">
        </code-input> -->
        <div class="center my-60">
            <ngx-otp-input [config]="otpInputConfig" (fill)="onCodeCompleted($event)" fxLayoutAlign=" center">
            </ngx-otp-input>
        </div>
        <div class="sign-up-account">
            <ng-container *ngIf="!sec">Didn’t receive the code? <span (click)="resend()">Resend Code</span>
            </ng-container>
            <ng-container *ngIf="sec">Resend OTP in {{sec}}s</ng-container>
        </div>
    </div>
</div>