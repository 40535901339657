import { HostListener } from "@angular/core";
import { Directive } from "@angular/core";

@Directive({
    selector: "[backPage]"
})
export class BackPageDirective {

    @HostListener("click")
    onClick() {
        if (window.history.length <= 2) {
            location.href = "/";
        }
        else
            window.history.back();
    }
}